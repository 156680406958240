<template>
  <a
    class="navbar__link navbar__link--active dropdown-toggle"
    href="#"
    @click.prevent
    v-text="language"
  />
</template>

<script setup>
import i18n from "@/i18n";
import { computed } from "vue";

const language = computed(() => i18n.global.locale);
</script>

<style scoped lang="scss">
.dropdown-toggle {
  text-transform: uppercase;
  display: inline-flex;
  justify-items: center;
  align-items: center;
  column-gap: 10px;
  margin-right: 10px;

  &:after {
    background: url("@/assets/menu/arrow-down-blue.svg");
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    right: 10px;

    @media screen and (max-width: $breakpoint-lg) {
      right: 0;
    }
  }
}
</style>
