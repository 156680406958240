import { defineStore } from "pinia";
import backService from "@/plugins/service";
import { computed, ref } from "vue";

export const useCountriesStore = defineStore("countries", () => {
  const current = ref(null);
  const loading = ref(false);
  const error = ref(null);
  const countries = ref([]);

  const currentCountryKey = computed(() => {
    if (!current.value || !countries.value) {
      return null;
    }

    return (
      Object.keys(countries.value).find((key) => key === current.value) || null
    );
  });

  const isCurrentCountryLithuania = computed(
    () => currentCountryKey.value === "LT",
  );

  const options = computed(() => {
    if (!countries.value) {
      return [];
    }

    return Object.entries(countries.value).map(([key, country]) => ({
      name: country,
      value: key,
    }));
  });

  const setCurrent = (value) => (current.value = value);
  const request = async () => {
    if (countries.value.length || loading.value) {
      return false;
    }

    loading.value = true;
    try {
      const { data } = await backService.get("/user/countries");

      loading.value = false;
      error.value = null;
      countries.value = data.data;

      return true;
    } catch (err) {
      loading.value = false;
      error.value = err;

      return false;
    }
  };

  return {
    current,
    loading,
    error,
    countries,
    currentCountryKey,
    isCurrentCountryLithuania,
    options,
    setCurrent,
    request,
  };
});
