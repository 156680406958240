<template>
  <Loading v-if="ssoLoginUrl" />
  <div v-else>
    <SimpleNavigation />

    <div class="auth-container">
      <div class="login">
        <h2>{{ $t("common.login_to_learning") }}</h2>

        <div class="login__wrap">
          <div class="login__image">
            <img src="@/assets/avatar.svg" alt="Avatar" />
          </div>

          <div class="login__block">
            <Form @submit="auth2Store.login">
              <TextField
                :rules="'required'"
                name="username"
                :placeholder="$t('common.username')"
              />
              <PasswordField
                :rules="'required'"
                name="password"
                :placeholder="$t('common.password')"
              />

              <div class="login__forgot">
                <router-link :to="{ name: ROUTE.Password.Forgot }">
                  {{ $t("common.forgot_password") }}
                </router-link>
              </div>

              <div class="login__center">
                <CustomButton
                  type="dark-login"
                  :disabled="false"
                  :full-width="true"
                  submit
                >
                  <template #text>
                    <Loading v-if="loading" size="20" color="white" />
                    <div v-else>{{ $t("common.login") }}</div>
                  </template>
                </CustomButton>
              </div>
            </Form>
          </div>

          <div v-if="socialStore.google">
            <div class="login__block login__block--divider">
              <h2>
                <span>{{ $t("common.or") }}</span>
              </h2>
            </div>

            <div class="login__block">
              <div class="login__socials">
                <GoogleLogin
                  v-bind="socialStore.google"
                  popup-type="TOKEN"
                  :callback="socialStore.googleCallback"
                >
                  <div class="login__google">
                    <img src="../../assets/google.svg" alt="Google logo" />
                    {{ $t("common.login_with_google") }}
                  </div>
                </GoogleLogin>
              </div>
            </div>
          </div>

          <p>
            {{ $t("common.dont_have_account") }}
            <router-link :to="{ name: 'register' }">
              {{ $t("common.create_new_account") }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SimpleNavigation from "@/components/SimpleNavigation";
import TextField from "@/components/form/TextField.vue";
import Loading from "@/components/Loading";
import PasswordField from "@/components/form/PasswordField.vue";
import CustomButton from "@/components/CustomButton.vue";
import i18n from "@/i18n";
import { Form } from "vee-validate";
import { useRoute, useRouter } from "vue-router";
import { GoogleLogin } from "vue3-google-login";
import { ROUTE } from "@/router/routenames";
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useAuthTokenHandler } from "@/util/useAuthTokenHandler";
import { useStores } from "@/store/use.store";
import {
  OAUTH_ACTION_LINK,
  OAUTH_ACTION_LOGIN,
} from "@/views/pages/LoginOptions";

const router = useRouter();
const route = useRoute();

const {
  alertStore,
  auth2Store,
  socialStore,
  cartStore,
  accountStore,
  keycloakStore,
} = useStores();

const { setupAuthWatcher } = useAuthTokenHandler({
  auth2Store,
  cartStore,
  accountStore,
  socialStore,
  router,
  route,
});

const loading = ref(false);
const ssoLoginUrl = ref(null);

const accessToken = computed(() => route.query.access_token);
const refreshToken = computed(() => route.query.refresh_token);
const environment = computed(() => route.query.environment);
const action = computed(() => route.query.action);

const error = computed(() => route.query.error);

onMounted(() => {
  ssoLoginUrl.value = null;
  if (process.env.VUE_APP_SSO_URL) {
    ssoLoginUrl.value =
      process.env.VUE_APP_SSO_URL + "?redirect=" + (route.query.redirect ?? "");
    if (route.query.register) {
      ssoLoginUrl.value += "&register=1";
    } else if (route.query.forgot) {
      ssoLoginUrl.value += "&forgot=1";
    }

    if (route.query.back_uri) {
      ssoLoginUrl.value += "&back_uri=" + route.query.back_uri;
    }
  }

  if (error.value) {
    alertStore.error(i18n.global.t("common.error"), error);
    console.error(error.value);
    router.replace({ name: ROUTE.Home });
  } else if (ssoLoginUrl.value && accessToken.value && !environment.value) {
    auth2Store.ssoLogin(accessToken.value, refreshToken.value);
  } else if (ssoLoginUrl.value && accessToken.value && environment.value) {
    if (action.value === OAUTH_ACTION_LOGIN) {
      auth2Store.loginWithOauthEnvironment(
        accessToken.value,
        refreshToken.value,
        environment.value,
      );
    }
    if (action.value === OAUTH_ACTION_LINK) {
      keycloakStore.linkOauthUser(accessToken.value, environment.value);
    }
  } else if (ssoLoginUrl.value) {
    window.location.replace(ssoLoginUrl.value);
  }
});

onBeforeMount(() => socialStore.request());

setupAuthWatcher();
</script>

<style scoped lang="scss">
.g-btn-wrapper {
  display: block !important;
}

.auth-container {
  max-width: 399px;
  margin: 0 auto;
  padding: 15px;
}

.login {
  &__forgot {
    margin-bottom: 20px;

    a {
      font-family: $font-family;
      color: $color-blue;
      text-decoration: none;
      font-size: 13px;
      font-weight: 500;
    }
  }

  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;
    margin-bottom: 40px;
  }

  &__socials {
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // gap: 10px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &--divider {
      padding: 40px 0px;

      h2 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dcdee9;
        line-height: 1px;
        margin: 10px 0 20px;

        opacity: 0.5;
        color: $color-blue;
        font-family: $font-family;
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
      }

      span {
        background-color: white;
        padding: 0 10px;
      }
    }
  }

  &__facebook {
    position: relative;
    border-radius: 4px;
    background-color: #1360c2;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    cursor: pointer;
    width: 100%;

    img {
      position: absolute;
      left: 20px;
    }
  }

  &__google {
    position: relative;
    border-radius: 8px;
    background-color: $color-blue;
    height: 52px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    cursor: pointer;

    width: 100%;

    img {
      margin-right: 15px;
    }
  }

  .login__forgot {
    text-align: right;

    a {
      font-weight: 400;
      line-height: 26px;
      color: $color-menu-item;
    }
  }

  p {
    text-align: center;
    margin: 40px 0;
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;

    a {
      color: $color-blue;
    }
  }
}
</style>
