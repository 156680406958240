<template>
  <div ref="search">
    <NavigationDesktop
      ref="navigationDesktop"
      :is-sticky="isSticky"
      :is-static="isStatic"
      :is-dark-color="isDarkColor"
      :show-transition="showTransition"
      :mega-menu-enable="megaMenuEnable"
      :is-search-enabled="isSearchEnabled"
      :is-shopping-enabled="isShopEnabled"
      :search-results="searchResults"
      :search-value="route.query.q"
      :change-language="changeLanguage"
      @toggle-mega-menu="toggleMegaMenu"
      @toggle-search="toggleSearch"
      @click-outside="clickOutside"
      @disable-search="disableSearch"
      @search="searchAction"
      @submit-search="submitSearch"
      @show-suggestions="showSuggestions"
      @open-course="openCourse"
    />

    <NavigationMobile
      :is-search-enabled="false"
      :is-shopping-enabled="isShopEnabled"
      :search-results="searchResults"
      :search-value="route.query.q"
      :open="open"
      :change-language="changeLanguage"
      @disable-search="disableSearch"
      @search="searchAction"
      @submit-search="submitSearch"
      @show-suggestions="showSuggestions"
      @open-course="openCourse"
    />

    <MenuMobile
      v-if="popupStore.menu"
      :is-search-enabled="isSearchEnabled"
      :search-results="searchResults"
      :search-value="route.query.q"
      @search="searchAction"
      @submit-search="submitSearch"
      @show-suggestions="showSuggestions"
      @open-course="openCourse"
      @disable-search="disableSearch"
      @toggle-search="toggleSearch"
    />
    <menu-mobile-settings v-if="popupStore.menuSettings" />
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";
import { getCurrentLanguageFilter } from "@/util/filters";
import MenuMobile from "./navigation/MenuMobile.vue";
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
  toRaw,
} from "vue";
import { isShoppingEnabled } from "@/util/helpers";
import { COLOR_DARK } from "@/store/modules/header.store";
import { storeToRefs } from "pinia";
import { onClickOutside } from "@vueuse/core";
import MenuMobileSettings from "@/components/navigation/MenuMobileSettings.vue";
import NavigationMobile from "@/components/NavigationMobile.vue";
import NavigationDesktop from "@/components/NavigationDesktop.vue";
import i18n from "@/i18n";
import { SCREEN_BREAKPOINT_XL } from "@/util/constants";
import { useStores } from "@/store/use.store";

const props = defineProps({ isStatic: { type: Boolean, default: true } });

const {
  cartStore,
  globalStore,
  popupStore,
  discountStore,
  headerStore,
  purchasedStore,
  productsStore,
} = useStores();

const router = useRouter();
const route = useRoute();
const { lightOrDark } = storeToRefs(headerStore);

const isSticky = ref(false);
const isSearchEnabled = ref(false);
const navigationDesktop = ref(null);
const searchResults = ref([]);
const search = ref(null);
const megaMenuEnable = ref(false);
const open = ref(false);

const isShopEnabled = computed(() => isShoppingEnabled());
const showTransition = computed(() => !megaMenuEnable.value);
const isDarkColor = computed(
  () =>
    lightOrDark.value === COLOR_DARK &&
    !isSticky.value &&
    !props.isStatic &&
    !megaMenuEnable.value,
);

responsive();

onBeforeMount(async () => {
  await cartStore.getItems();
  discountStore.request();
});
onMounted(async () => {
  onClickOutside(search, () => {
    isSearchEnabled.value = false;
    searchResults.value = [];
  });
});
onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const toggleMegaMenu = () => {
  megaMenuEnable.value = !megaMenuEnable.value;
};
const toggleSearch = (event) => {
  event.preventDefault();
  isSearchEnabled.value = !isSearchEnabled.value;
  if (
    window.innerWidth <= SCREEN_BREAKPOINT_XL &&
    ((isShopEnabled.value && route.name === ROUTE.Discover) ||
      (!isShopEnabled.value && route.name === ROUTE.Dashboard.Courses))
  ) {
    isSearchEnabled.value = false;
    popupStore.closeMenu();
  }
};
const clickOutside = () => {
  megaMenuEnable.value = false;
};
const onFilter = (filter, group) => {
  globalStore.updateFilters(filter);
  megaMenuEnable.value = false;

  router.push({
    name: ROUTE.Discover,
    query: {
      filter_group_id: group.filter_group_id,
      filter_id: filter.filter_id,
    },
  });
};

const searchAction = (values) => {
  popupStore.closeMenu();
  if (isShopEnabled.value) {
    if (route.name === ROUTE.Discover) {
      router.replace({
        name: ROUTE.Discover,
        query: { q: values.q },
      });
    } else {
      router.push({ name: ROUTE.Discover, query: { q: values.q } });
    }
  } else {
    if (route.name === ROUTE.Dashboard.Courses) {
      this.$router.replace({
        name: ROUTE.Dashboard.Courses,
        query: { q: values.q },
      });
    } else {
      router.push({
        name: ROUTE.Dashboard.Courses,
        query: { q: values.q },
      });
    }
  }
};
const showSuggestions = async (value) => {
  if (isShopEnabled.value) {
    await productsStore.requestProductsBySearch(value);
    searchResults.value = productsStore.productsBySearch;
  } else {
    await purchasedStore.getCourses(value);
    searchResults.value = purchasedStore.courses;
  }
};
const submitSearch = (value) => {
  popupStore.closeMenu();
  router.push({
    name: isShopEnabled.value ? ROUTE.Discover : ROUTE.Dashboard.Courses,
    query: { q: value },
  });
};
const disableSearch = () => {
  isSearchEnabled.value = false;
  searchResults.value = [];
};
const openCourse = async (id, environment) => {
  await router.push({
    name: isShopEnabled.value ? ROUTE.Course.About : ROUTE.Course.General,
    params: {
      id: id,
      ...(isShopEnabled.value ? {} : { environment: environment }),
    },
  });
};
const changeLanguage = () => {
  if (ROUTE.Discover === router.currentRoute.value.name) {
    const { filterGroup, filter } = getCurrentLanguageFilter();

    if (filter && filterGroup) {
      globalStore.resetLanguageFilters(i18n.global.availableLocales);
      onFilter(filter, filterGroup);
    } else if (!filter) {
      globalStore.resetLanguageFilters(i18n.global.availableLocales);
    }
  }
};

function handleScroll() {
  isSticky.value =
    window.scrollY > toRaw(navigationDesktop.value?.navbar)?.offsetTop;
}

function handleResize() {
  open.value = false;
  megaMenuEnable.value = false;
}

function responsive() {
  window.addEventListener("resize", handleResize, true);
  window.addEventListener("scroll", handleScroll);

  if (window.innerWidth <= SCREEN_BREAKPOINT_XL) {
    open.value = false;
  }
}
</script>

<style lang="scss" scoped></style>
