<template>
  <iframe
    v-if="settingsStore.values.iframe_url"
    v-show="false"
    :src="settingsStore.values.iframe_url"
  />
</template>
<script setup>
import { useAuth2Store } from "@/store/modules/auth2.store";
import { useSettingsStore } from "@/store/modules/settings.store";
import { watch } from "vue";

const auth2Store = useAuth2Store();
const settingsStore = useSettingsStore();

watch(
  () => auth2Store.token,
  (token) => (token ? settingsStore.request() : null),
);
</script>
