<template>
  <div class="company-types">
    <div class="container">
      <h3 v-dompurify-html="content.title" />
      <p v-dompurify-html="content.content" />
    </div>
    <HighlightGrid :items="information" :on-click="goToPage" />
  </div>
</template>

<script setup>
import { onBeforeMount, ref, watch } from "vue";
import HighlightGrid from "@/components/grid/HighlightGrid.vue";
import { useRoute, useRouter } from "vue-router";
import i18n from "@/i18n";
import { buildDataFromChapters, formatIndex } from "@/util/helpers";
import backService from "@/plugins/service";

defineProps({
  content: Object,
});

const router = useRouter();
const route = useRoute();

const information = ref([]);
const loading = ref(false);

const getInformationPages = async () => {
  loading.value = true;
  try {
    const response = await backService.get("/information?bottom=true");
    information.value = buildDataFromChapters(
      response.data?.chapters || [],
      false,
    );
  } catch (error) {
    console.error("Failed to fetch information pages:", error);
  } finally {
    loading.value = false;
  }
};
const goToPage = (item) => {
  const index = information.value.findIndex((info) => info.id === item.id);
  router.push({
    name: "page",
    params: { id: item.id },
    query: { number: formatIndex(index + 1), bottom: true },
  });
};
const scrollToGrid = () => {
  if (!route.query.scrollTo) {
    return;
  }
  const element = document.getElementById(route.query.scrollTo);
  if (!element) {
    return;
  }
  element.scrollIntoView({ behavior: "smooth" });
};

onBeforeMount(async () => {
  await getInformationPages();
  scrollToGrid();
});

watch(
  () => i18n.global.locale,
  async () => await getInformationPages(),
);
</script>

<style lang="scss" scoped>
.company-types {
  margin-top: 20px;
  margin-bottom: 50px;

  h3 {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-slg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    margin-bottom: 5px;
  }

  p {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }

  &__wrap {
    margin-top: 60px;

    display: flex;
    align-items: center;
    gap: 11px;
  }

  &__block {
    flex-basis: 100%;

    img {
      width: 100%;
    }

    border: 1px solid #dcdee9;
    background-color: #ffffff;

    text-align: center;

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
    }

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;
      font-weight: 100;

      margin-bottom: 10px;
    }

    p {
      opacity: 0.5;
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center;

      margin-bottom: 30px;
    }
  }

  &__content {
    padding: 17px;
  }
}
</style>
