import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { useCountriesStore } from "./countries.store";
import backService from "@/plugins/service";

export const useCitiesStore = defineStore("cities", () => {
  const loading = ref(false);
  const error = ref(null);
  const data = reactive(new Map());

  const countriesStore = useCountriesStore();

  const options = computed(() => {
    if (
      !countriesStore.currentCountryKey ||
      !data.has(countriesStore.currentCountryKey)
    ) {
      return [];
    }

    const country = data.get(countriesStore.currentCountryKey);
    if (!country) {
      return [];
    }

    return country.map((country) => ({
      name: country.name,
      value: country.name,
    }));
  });

  const request = async () => {
    const country = countriesStore.currentCountryKey;
    if (!country || data.has(country)) {
      return true;
    }

    loading.value = true;
    error.value = null;

    try {
      const { data: responseData } = await backService.get(
        "/user/cities?alt=" + country,
      );
      data.set(country, responseData.data);

      return true;
    } catch (err) {
      loading.value = false;
      error.value = err;

      return false;
    }
  };

  return {
    loading,
    error,
    data,
    options,
    request,
  };
});
