<template>
  <div class="search">
    <div class="search__container">
      <div class="search__container__search-wrapper">
        <Form :initial-values="{ q: searchString }" @submit="search">
          <div class="search-wrap">
            <SearchForm
              @search="showSuggestions"
              @disable:search="disableSearch"
              @submit:search="submitSearch"
            />
          </div>
        </Form>
      </div>
      <div>
        <ul class="filtered-courses">
          <li
            v-for="(searchResult, key) in searchResults"
            @click="openCourse(searchResult.id, searchResult.environment)"
          >
            <img alt="Course image" :src="searchResult.image" />
            <span v-text="searchResult.title" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import SearchForm from "@/components/search/SearchForm.vue";
import { computed, defineEmits } from "vue";

const props = defineProps({
  searchResults: Array,
  searchValue: String,
});

const emit = defineEmits([
  "search",
  "show:suggestions",
  "disable:search",
  "submit:search",
  "open:course",
]);

const searchString = computed(() => props.searchValue);

const search = (value) => emit("search", value);
const showSuggestions = (value) => emit("show:suggestions", value);
const disableSearch = () => emit("disable:search");
const submitSearch = (value) => emit("submit:search", value);
const openCourse = (courseId, environment) =>
  emit("open:course", courseId, environment);
</script>

<style lang="scss" scoped>
.search {
  position: fixed;
  left: 0;
  right: 0;
  background-color: $color-white;
  padding-bottom: 50px;
  top: 0;
  z-index: 5;

  @media screen and (max-width: $breakpoint-lg) {
    padding-bottom: 0;
  }

  &__container {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    &__search-wrapper {
      justify-content: space-between;
      align-items: center;
      gap: 80px;
      padding: 9px 10px;

      @media screen and (max-width: $breakpoint-lg) {
        gap: 20px;
      }

      &--responsive {
        margin-left: 12px;
        margin-right: auto;

        input {
          width: 100% !important;
        }
      }

      form {
        width: 100%;
      }

      a {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}

.filtered-courses {
  padding: 0 10px;
  max-height: 300px;
  overflow: auto;

  li {
    cursor: pointer;
    display: flex;
    gap: 15px;
    align-items: center;
    border-top: 1px solid #f1f5f9;
    border-bottom: 1px solid #f1f5f9;
  }

  img {
    width: 88px;
    height: 66px;
    object-fit: contain;
  }

  span {
    color: $color-primary;
    font-size: $font-size-md;
    padding: 3px 0;
  }
}
</style>
