<template>
  <div>
    <SimpleNavigation />
    <div class="auth-container">
      <div class="login">
        <div v-if="!selectedEnvironment" class="login__wrap">
          <h2>{{ $t("common.login_to_learning") }}</h2>
          <div class="login__image">
            <img src="@/assets/avatar.svg" alt="Avatar" />
          </div>
          <button v-if="showEnvironments" class="go-back" @click="goBack">
            <img alt="back" :src="ArrowBack" />
          </button>
          <template v-if="showEnvironments">
            <div
              v-for="(environment, key) in keycloakStore.environmentsList"
              :key="key"
              class="buttons-wrapper"
            >
              <CustomButton
                class="login-button"
                flex
                type="default"
                @click="loginToEnvironment(environment)"
              >
                <template #text>
                  <img alt="logo" :src="environment.logo" />
                  <p
                    v-text="
                      $t('sso.login_with_environment', {
                        environment: environmentName(environment),
                      })
                    "
                  />
                </template>
              </CustomButton>
            </div>
          </template>
          <template v-else>
            <Form @submit="loginWithSSO">
              <TextField
                :rules="'required'"
                name="username"
                :placeholder="$t('common.username')"
              />
              <PasswordField
                :rules="'required'"
                name="password"
                :placeholder="$t('common.password')"
              />

              <div class="login__center">
                <CustomButton
                  type="dark-login"
                  :disabled="loading"
                  :full-width="true"
                  submit
                >
                  <template #text>
                    <Loading v-if="loading" size="20" color="white" />
                    <div v-else>{{ $t("common.login") }}</div>
                  </template>
                </CustomButton>
              </div>

              <p class="forgot-password">
                <router-link
                  :to="{
                    name: ROUTE.Login,
                    query: {
                      redirect: route.query.redirect,
                      forgot: true,
                      back_uri: backUri,
                    },
                  }"
                >
                  {{ $t("common.forgot_password_sso") }}
                </router-link>
              </p>
            </Form>
            <AppSeparator class="separator" />
            <CustomButton
              class="login-button"
              flex
              type="default"
              :margin-top="15"
              :disabled="loading"
              @click="handleIdentityProviderLogin"
            >
              <template #text>
                <img alt="logo" :src="GoogleLogo" />
                <p v-text="$t('sso.login_with_google')" />
              </template>
            </CustomButton>
            <!--            <CustomButton-->
            <!--              class="select-environment-button"-->
            <!--              flex-->
            <!--              type="default"-->
            <!--              :margin-top="15"-->
            <!--              :disabled="loading"-->
            <!--              @click="openEnvironmentsList"-->
            <!--            >-->
            <!--              <template #text>-->
            <!--                <span v-text="$t('sso.sign_in_with_environment')" />-->
            <!--                <button class="select-env">-->
            <!--                  <img alt="back" :src="ArrowBack" />-->
            <!--                </button>-->
            <!--              </template>-->
            <!--            </CustomButton>-->
            <p class="register">
              {{ $t("common.dont_have_account") }}
              <router-link
                :to="{
                  name: ROUTE.Login,
                  query: {
                    redirect: route.query.redirect,
                    register: true,
                    back_uri: backUri,
                  },
                }"
              >
                {{ $t("common.create_new_account") }}
              </router-link>
            </p>
          </template>
        </div>
        <div v-else class="login__block">
          <div class="header-block">
            <img alt="Logo" :src="selectedEnvironment.logo" class="logo" />
            <h2
              class="title"
              v-text="
                $t('sso.login_to_environment', {
                  environment: selectedEnvironment.environment,
                })
              "
            />
          </div>
          <button class="go-back" @click="goBack">
            <img alt="back" :src="ArrowBack" />
          </button>
          <Form @submit="login">
            <TextField
              :rules="'required'"
              name="username"
              :placeholder="$t('common.username')"
            />
            <PasswordField
              :rules="'required'"
              name="password"
              :placeholder="$t('common.password')"
            />
            <div class="login__center">
              <CustomButton
                type="dark-login"
                :disabled="loading"
                :full-width="true"
                submit
              >
                <template #text>
                  <Loading v-if="loading" size="20" color="white" />
                  <div v-else>{{ $t("common.login") }}</div>
                </template>
              </CustomButton>
            </div>
          </Form>
          <p class="forgot-password">
            <a
              :href="
                selectedEnvironment.lmsUrl + ENVIRONMENT_FORGOT_PASSWORD_PATH
              "
              target="_blank"
              >{{ $t("common.forgot_password_sso") }}</a
            >
          </p>
          <CustomButton
            v-for="(service, serviceId) in environmentOauthServices"
            :key="serviceId"
            class="env-oauth login-button"
            flex
            type="default"
            :margin-top="15"
            :disabled="loading"
            @click="oauthEnvClick(service.servicetype)"
          >
            <template #text>
              <img alt="service" :src="service.image" />
              <span v-text="service.name" />
            </template>
          </CustomButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SimpleNavigation from "@/components/SimpleNavigation";
import CustomButton from "@/components/CustomButton.vue";
import { useRoute, useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";
import { Form } from "vee-validate";
import TextField from "@/components/form/TextField.vue";
import PasswordField from "@/components/form/PasswordField.vue";
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import { formEnvironmentOauthUrl, isShoppingEnabled } from "@/util/helpers";
import ArrowBack from "@/assets/arrow_long_left.svg";
import AppSeparator from "@/components/AppSeparator.vue";
import GoogleLogo from "@/assets/google-blue.svg";
import Loading from "@/components/Loading.vue";
import i18n from "@/i18n";
import { useAuthTokenHandler } from "@/util/useAuthTokenHandler";
import { useStores } from "@/store/use.store";
import {
  ENVIRONMENT_FORGOT_PASSWORD_PATH,
  OAUTH_ACTION_LOGIN,
  ORIGIN_TYPE,
} from "@/views/pages/LoginOptions";

const router = useRouter();
const route = useRoute();

const {
  alertStore,
  auth2Store,
  accountStore,
  cartStore,
  environmentStore,
  keycloakStore,
  socialStore,
} = useStores();

const { setupAuthWatcher } = useAuthTokenHandler({
  auth2Store,
  cartStore,
  accountStore,
  socialStore,
  router,
  route,
});

const selectedEnvironment = ref(null);
const showEnvironments = ref(false);
const loading = ref(false);
const backUri = ref("");

const environmentOauthServices = computed(() =>
  selectedEnvironment.value
    ? environmentStore.environmentOauthServices[
        selectedEnvironment.value.environment
      ]
    : [],
);

const loginWithSSO = async (event) => {
  loading.value = true;
  try {
    await auth2Store.ssoLoginWithCredentials(event.username, event.password);
  } catch (error) {
    if (error.response?.status === 401) {
      alertStore.error(i18n.global.t("common.invalid_credentials"));
    } else {
      alertStore.error(
        error.response?.data?.message || i18n.global.t("common.general_error"),
      );
    }
  } finally {
    loading.value = false;
  }
};
const loginToEnvironment = (environment) => {
  selectedEnvironment.value = environment;
  showEnvironments.value = false;
};

// const openEnvironmentsList = () => {
//   selectedEnvironment.value = null;
//   showEnvironments.value = true;
// };

const login = async (event) => {
  loading.value = true;
  try {
    await auth2Store.loginWithEnvironment(
      event.username,
      event.password,
      selectedEnvironment.value.environment,
    );
  } finally {
    loading.value = false;
  }
};

const handleIdentityProviderLogin = () => {
  const redirectUrl = route.query.redirect;
  window.location.href =
    process.env.VUE_APP_SSO_URL +
    "?app=eshop&identity_provider=google&redirect=" +
    redirectUrl;
};

const goBack = () => {
  if (showEnvironments.value) {
    showEnvironments.value = false;
    selectedEnvironment.value = null;
  }
  if (selectedEnvironment.value) {
    selectedEnvironment.value = false;
    showEnvironments.value = true;
  }
};

const oauthEnvClick = async (serviceType) => {
  await environmentStore.getOauthData(
    selectedEnvironment.value.environment,
    serviceType,
  );
  if (environmentStore.oauthId) {
    const environment = keycloakStore.environmentsList.find(
      (environment) =>
        environment.environment === selectedEnvironment.value.environment,
    );

    window.location.href = formEnvironmentOauthUrl(
      environment.lmsUrl,
      environmentStore.oauthId,
      route.query.redirect,
      ORIGIN_TYPE,
      OAUTH_ACTION_LOGIN,
    );
  }
};

const environmentName = (environment) =>
  environment.name || environment.environment;

onBeforeMount(() => {
  if (!isShoppingEnabled()) {
    router.replace({
      name: ROUTE.Login,
      query: { redirect: route.query.redirect },
    });
  }
});

onMounted(() => {
  backUri.value = window.location.href;
});

watch(
  () => selectedEnvironment.value,
  () => {
    if (selectedEnvironment.value) {
      environmentStore.getOauthEnvironments(
        selectedEnvironment.value.environment,
      );
    }
  },
);

setupAuthWatcher();
</script>

<style scoped lang="scss">
.auth-container {
  max-width: 399px;
  margin: 0 auto;
  padding: 15px;
}

.login-button {
  width: 100%;

  img {
    width: 30px;
    height: 30px;
  }
}

.env-oauth.login-button {
  justify-content: center;
}

.buttons-wrapper {
  margin-top: 15px;

  .login-button {
    margin-bottom: 15px;
  }
}

.login {
  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    text-align: center;
    margin-bottom: 40px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;

    .forgot-password {
      margin-top: 16px;
      text-align: right;
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .forgot-password {
      text-align: right;
    }

    &--divider {
      padding: 40px 0px;

      h2 {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #dcdee9;
        line-height: 1px;
        margin: 10px 0 20px;

        opacity: 0.5;
        color: $color-blue;
        font-family: $font-family;
        font-size: 13px;
        letter-spacing: 0;
        text-align: center;
      }

      span {
        background-color: white;
        padding: 0 10px;
      }
    }
  }

  p {
    text-align: center;
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;

    a {
      color: $color-blue;
    }
  }

  .login-hybridlab {
    p {
      color: $color-white;
    }
  }
}

.go-back,
.select-env {
  background-color: unset;
  border: unset;
  cursor: pointer;
}

.select-env {
  transform: rotate(180deg);
}

.select-environment-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    text-align: center;
    color: #64748b;
    font-family: Ubuntu;
    font-size: 14px;
    letter-spacing: 0;
  }
}

.register {
  margin-top: 30px;
}

.header-block {
  display: block;
  margin: 0 auto;
  text-align: center;

  .logo {
    width: 100%;
    max-width: 106px;
  }
}
</style>
