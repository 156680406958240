import { defineStore } from "pinia";
import { ref } from "vue";
import backService from "@/plugins/service";
import { buildDataFromChapters } from "@/util/helpers";

export const useSlideshowStore = defineStore("slideshow", () => {
  const loading = ref(false);
  const list = ref([]);

  async function request(type) {
    try {
      loading.value = true;
      const { data } = await backService.get("slideshows", {
        params: { type },
      });

      loading.value = false;
      list.value = buildDataFromChapters(data?.chapters || [], false);

      return true;
    } catch (error) {
      loading.value = false;
      console.error(error);

      return false;
    }
  }

  return {
    list,
    loading,

    request,
  };
});
