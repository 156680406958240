<template>
  <SettingsForm :initial-values="initialValues">
    <SettingsFormItem :title="$t('common.gender')">
      <SelectInputField
        name="gender"
        :placeholder="$t('common.your_gender')"
        :options="genders"
        @input="(value) => setGender(value)"
      />
    </SettingsFormItem>
    <SettingsFormItem :title="$t('common.birthday_date')" required>
      <div class="calendar-container">
        <div @click="showCalendar">
          <TextInputField
            v-model="form.date_of_birth"
            name="date_of_birth"
            rules="required"
            placeholder="1900-01-01"
            @input="onBirthdateChange"
          />
        </div>
        <v-date-picker
          v-if="calendarEnabled"
          ref="calendar"
          :model-value="form.date_of_birth"
          locale="lt"
          color="red"
          is-expanded
          @dayclick="onDayClick"
        />
      </div>
    </SettingsFormItem>
  </SettingsForm>
</template>

<script setup>
import SelectInputField from "../SelectInputField.vue";
import { defineEmits, defineProps, ref } from "vue";
import TextInputField from "../TextInputField.vue";
import SettingsForm from "@/components/form/dashboard/components/SettingsForm.vue";
import SettingsFormItem from "@/components/form/dashboard/components/SettingsFormItem.vue";
import { onClickOutside } from "@vueuse/core";
import debounce from "lodash.debounce";

const emit = defineEmits(["submit"]);
const props = defineProps({ initialValues: Object, loading: Boolean });

const calendar = ref(null);
const form = ref({
  date_of_birth: props.initialValues.date_of_birth,
});
const calendarEnabled = ref(false);

const genders = [
  {
    name: "Vyras",
    value: "male",
  },
  {
    name: "Moteris",
    value: "female",
  },
];

const setGender = (value) => {
  if (!value || value === props.initialValues["gender"]) {
    return;
  }

  emit("submit", { gender: value });
};
const setBirthdate = (value) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(value)) {
    return;
  }
  form.value.date_of_birth = value;
  emit("submit", { date_of_birth: value });
};
const showCalendar = () => {
  calendarEnabled.value = !calendarEnabled.value;
};
const onDayClick = (selectedDay) => {
  const month =
    selectedDay.month < 10 ? `0${selectedDay.month}` : selectedDay.month;
  const day = selectedDay.day < 10 ? `0${selectedDay.day}` : selectedDay.day;
  setBirthdate(`${selectedDay.year}-${month}-${day}`);
  calendarEnabled.value = false;
};
const onBirthdateChange = debounce(
  () => setBirthdate(form.value.date_of_birth),
  1000,
);

onClickOutside(calendar, () => (calendarEnabled.value = false));
</script>

<style lang="scss" scoped>
.calendar-container {
  position: relative;
}

:deep(.vc-container) {
  border: none;
  position: absolute;
  box-shadow: -2px 2px 12px 0px #1b214a1a;
  background-color: $color-white;
  z-index: 2;
}

:deep(.vc-day) {
  color: $color-primary;
  padding: 0 5px;
}

:deep(.vc-day.weekday-7),
:deep(.vc-day.weekday-1) {
  color: red;
}

:deep(.vc-day-content) {
  font-weight: 500;
  font-size: $font-size-xs;
  font-family: $font-family;
  width: 32px;
  height: 32px;
}

:deep(.vc-day-content) {
  font-weight: 500;
  font-size: $font-size-xs;
  font-family: $font-family;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

:deep(.vc-weekday) {
  color: $color-menu-item;
  font-size: $font-size-xs;
  font-family: $font-family;
  font-weight: 500;
}

:deep(.vc-title) {
  color: $color-primary;
  background: transparent;
  font-family: $font-family;
  font-size: $font-size-xs;
  font-weight: 500;
}

:deep(.vc-arrow) {
  background: transparent;
  width: 12px;
}
</style>
