<template>
  <div class="item">
    <div class="block-header">
      <p class="title" v-text="$t('sso.connected_accounts')" />
      <p class="description" v-text="$t('sso.select_service')" />
    </div>
    <div class="buttons-wrapper">
      <CustomButton
        v-for="(environment, key) in environments"
        :key="key"
        class="environment-button"
        flex
        :type="isEnvironmentLinked(environment) ? 'dark' : 'grey-transparent'"
        @click="handleAction(environment)"
      >
        <template #text>
          <img alt="logo" :src="environment.logo" />
          <p
            v-text="
              $t(isEnvironmentLinked(environment) ? 'sso.unlink' : 'sso.link', {
                environment: environmentName(environment).toUpperCase(),
              })
            "
          />
        </template>
      </CustomButton>
    </div>
  </div>
  <LinkToEnvironment
    v-if="showPopup"
    :environment="selectedEnvironment"
    @close:popup="close"
    @success="close"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import LinkToEnvironment from "@/components/modals/LinkToEnvironment.vue";
import { useKeyCloakStore } from "@/store/modules/keycloak.store";
import CustomButton from "@/components/CustomButton.vue";

const props = defineProps({
  environments: {
    type: Array,
    required: true,
  },
  userEnvironments: {
    type: Array,
    required: true,
  },
});

const keyCloakStore = useKeyCloakStore();

const showPopup = ref(false);
const selectedEnvironment = ref(null);

const userEnvironmentsList = computed(() =>
  Object.keys(props.userEnvironments),
);

const isEnvironmentLinked = (environment) =>
  userEnvironmentsList.value.includes(environment.environment);

const handleAction = (environment) => {
  selectedEnvironment.value = environment;
  const isLinked = isEnvironmentLinked(environment);
  if (!isLinked) {
    showPopup.value = true;
    return;
  }
  keyCloakStore.unlinkUser(selectedEnvironment.value.environment);
};

const close = () => (showPopup.value = false);

const environmentName = (environment) =>
  environment.name || environment.environment;
</script>
<style lang="scss" scoped>
.item {
  padding: 8px 20px;

  .block-header {
    margin-bottom: 36px;

    .title {
      font-size: $font-size-md;
      color: $color-black;
    }

    .description {
      font-size: $font-size-sm;
      color: #64748b;
    }
  }

  .buttons-wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px;

    @media screen and (max-width: $breakpoint-lg) {
      grid-template-columns: auto auto auto;
    }
    @media screen and (max-width: $breakpoint-sm) {
      grid-template-columns: auto;
    }

    .environment-button {
      padding: 16px 50px;
    }
  }
}

img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
</style>
