<template>
  <div class="menu-mobile-settings">
    <div class="menu-mobile-settings-overlay" />
    <div class="menu-mobile-settings-content">
      <MenuMobileNavbar />
      <div class="menu-mobile-settings-content-menu">
        <div
          class="menu-mobile-settings-content__profile-block"
          @click="router.push({ name: ROUTE.Dashboard.Dashboard })"
        >
          <div class="menu-mobile-settings-content__profile-block-name">
            {{ settingsStore.values.firstname }}
            {{ settingsStore.values.lastname }}
          </div>
          <div
            v-if="settingsStore.values.username"
            class="menu-mobile-settings-content__profile-block-username"
          >
            {{ settingsStore.values.username }}
          </div>
          <img
            v-if="isShopEnabled"
            class="menu-mobile-settings-content__profile-block-action"
            src="@/assets/arrow-long-right-gray.svg"
            alt="arrow"
          />
        </div>
        <div
          v-if="isShopEnabled"
          class="menu-mobile-settings-content__profile-block"
        >
          <router-link :to="{ name: ROUTE.Dashboard.Favourites }">
            {{ $t("common.favourites") }}
          </router-link>
        </div>
        <div
          v-if="!isShopEnabled"
          class="menu-mobile-settings-content__profile-block"
        >
          <router-link :to="{ name: ROUTE.Dashboard.Settings }">
            {{ $t("common.settings") }}
          </router-link>
        </div>
        <old-app v-if="auth2Store.canViewMoodle" />
        <div class="menu-mobile-settings-content__profile-block">
          <div
            class="menu-mobile-settings-content__profile-block--logout"
            @click="logout"
          >
            <img src="../../assets/menu/logout-red.svg" alt="logout" />
            <span>{{ $t("common.logout") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuMobileNavbar from "./MenuMobileNavbar.vue";
import { computed, onBeforeUnmount, onMounted } from "vue";
import { useRouter } from "vue-router";
import { isShoppingEnabled } from "@/util/helpers";
import OldApp from "@/components/navbar/OldApp.vue";
import { ROUTE } from "@/router/routenames";
import { SCREEN_BREAKPOINT_XL } from "@/util/constants";
import { useStores } from "@/store/use.store";

const { popupStore, auth2Store, settingsStore } = useStores();

const router = useRouter();
const responsive = window.matchMedia(
  "screen and (max-width: " + SCREEN_BREAKPOINT_XL + "px)",
);

const isShopEnabled = computed(() => isShoppingEnabled());

function handleNavigation(to, from, next) {
  popupStore.closeMenu();
  next();
}

const logout = () => {
  popupStore.closeMenu();
  auth2Store.logout();
};

onMounted(() => {
  responsive.addEventListener("change", popupStore.closeMenu);
});

const unsubscribe = router.beforeEach(handleNavigation);
onBeforeUnmount(() => {
  responsive.removeEventListener("change", popupStore.closeMenu);
  unsubscribe();
});
</script>

<style lang="scss" scoped>
.menu-mobile-settings {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 255, 0.05);
    z-index: $z-index-menu;
  }

  &-content {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    background: $color-white;
    z-index: $z-index-menu;
    font-family: $font-family;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &__profile-block {
      position: relative;

      &-name {
        color: $color-primary;
        font-family: $font-family;
        font-size: $font-size-slg;
        letter-spacing: 0;
        line-height: 15px;
        font-weight: 500;
      }

      &-username {
        color: $color-primary;
        font-family: $font-family;
        font-size: $font-size-md;
        letter-spacing: 0;
        line-height: 15px;
        font-weight: 400;
        margin-top: 8px;
      }

      &-action {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }

      &--logout {
        color: $color-light-coral;
        font-family: $font-family;
        font-size: $font-size-xs;
        letter-spacing: 0;
        line-height: 12px;
        font-weight: 400;
        cursor: pointer;
        display: inline-flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      &:first-child {
        padding: 26px 20px;
      }

      &:last-child {
        padding: 30px 20px;
        box-shadow: unset;
      }

      a {
        display: block;
        padding: 16px 20px;

        color: $color-menu-item;
        font-family: $font-family;
        font-size: $font-size-md;
        letter-spacing: 0;
        line-height: 15px;

        text-decoration: none;
        box-shadow: none;
      }

      box-shadow: inset 0 -1px 0 0 $color-light-grey;
    }
  }
}
</style>
