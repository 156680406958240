import axios from "axios";
import i18n from "@/i18n";

import { useAlertStore } from "@/store";
import { useGuestStore } from "@/store/modules/guest.store";
import { useAuth2Store } from "@/store/modules/auth2.store";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export const lmsAxios = axios.create();

export const convertEnToGb = (val) => {
  switch (val) {
    case "en":
      return "en-gb";
    case "lt":
      return "lt-lt";
    case "ru":
      return "ru-ru";
    default:
      return val;
  }
};

export const axiosResponseInterceptorOnError = (instance) => async (error) => {
  const auth2Store = useAuth2Store();
  const alertStore = useAlertStore();

  if (error && error.response) {
    if (error.response.status === 429) {
      alertStore.warning(
        "Per greitai vykdote užklausas.",
        "Prašome palaukti vieną minutę kol vėl galėsite toliau naudotis svetaine.",
      );
    }

    if (error.response.status === 400 && !error.config.skipError) {
      if (error.response.data) {
        if (
          error.response.data.error &&
          Array.isArray(error.response.data.error)
        ) {
          error.response.data.error.forEach((error) => {
            alertStore.error(i18n.global.t("common.error"), error);
          });
        } else if (error.response.data.message) {
          alertStore.error(
            i18n.global.t("common.error"),
            error.response.data.message,
          );
        } else if (error.response.data.password) {
          error.response.data.password.forEach((error) => {
            alertStore.error(i18n.global.t("common.error"), error);
          });
        }
      }
    }

    if ([401, 403].includes(error.response.status)) {
      if (auth2Store.isLoggedIn) {
        return await handleRefresh(instance, error);
      }
    }

    if (error.response.status === 500) {
      // send request for logging about error
    }

    if (error.response.status === 422) {
      //
    }
  }

  throw error;
};

export const handleRefresh = async (instance, error) => {
  const originalRequest = error.config;
  const auth2Store = useAuth2Store();

  if (originalRequest.url.includes("logout")) {
    return Promise.reject(error);
  }

  if (originalRequest.url.includes("auth/refresh")) {
    await auth2Store.logout();
    return Promise.reject(error);
  }

  try {
    const refreshSuccess = await auth2Store.refresh(true);
    if (refreshSuccess) {
      return instance(originalRequest);
    } else {
      await auth2Store.logout();
      return Promise.reject(error);
    }
  } catch (refreshError) {
    await auth2Store.logout();
    return Promise.reject(refreshError);
  }
};

axios.interceptors.request.use((request) => {
  const auth2Store = useAuth2Store();
  const guestStore = useGuestStore();

  request.headers["X-Requested-With"] = "XMLHttpRequest";
  request.headers["X-Oc-Merchant-Id"] = process.env.VUE_APP_SECRET;
  request.headers["X-Oc-Merchant-Language"] = convertEnToGb(i18n.global.locale);

  if (auth2Store.ocSessionId) {
    request.headers["X-Oc-Session"] = auth2Store.ocSessionId;
  } else {
    request.headers["X-Oc-Session"] = guestStore.session;
  }

  return request;
});

axios.interceptors.response.use(
  (response) => response,
  axiosResponseInterceptorOnError(axios),
);

lmsAxios.interceptors.response.use(
  (response) => response,
  axiosResponseInterceptorOnError(lmsAxios),
);
