<template>
  <FullPageModal
    name="link_to_environment"
    :toggle="true"
    :back="true"
    @close="close"
  >
    <template #body>
      <div class="flex-container">
        <Transition>
          <div class="form-block">
            <div class="logo-wrapper">
              <img alt="Logo" :src="environment.logo" class="logo" />
            </div>
            <div class="header-block">
              <h2 v-text="$t('sso.please_login')" />
              <h4
                v-text="
                  $t('sso.login_description', {
                    environment: environmentName,
                  })
                "
              />
            </div>
            <div class="form">
              <Form @submit.prevent="linkUser">
                <label for="username" v-text="$t('common.your_email')" />
                <TextField
                  :rules="'required'"
                  name="username"
                  :placeholder="$t('sso.username')"
                />
                <label for="username" v-text="$t('common.your_password')" />
                <PasswordField
                  class="mb-0"
                  :rules="'required'"
                  name="password"
                  :placeholder="$t('sso.password')"
                />
                <a
                  target="_blank"
                  class="forgot-password"
                  :href="
                    props.environment.lmsUrl + ENVIRONMENT_FORGOT_PASSWORD_PATH
                  "
                  >{{ $t("sso.forgot_password") }}</a
                >
                <div class="action-buttons">
                  <div class="login-block">
                    <CustomButton
                      class="cancel"
                      :type="'grey-transparent'"
                      @click.prevent="close"
                    >
                      <template #text>
                        <div>{{ $t("common.cancel_upper") }}</div>
                      </template>
                    </CustomButton>
                    <CustomButton
                      class="login"
                      type="'transparent-blue'"
                      :disabled="linkLoading"
                      submit
                    >
                      <template #text>
                        <Loading v-if="linkLoading" size="20" color="white" />
                        <div v-else>{{ $t("common.login") }}</div>
                      </template>
                    </CustomButton>
                  </div>
                  <CustomButton
                    :type="'dark'"
                    :disabled="registerLoading"
                    :margin-top="15"
                    class="register-button"
                    @click.prevent="register"
                  >
                    <template #text>
                      <Loading v-if="registerLoading" size="20" color="white" />
                      <div v-else>{{ $t("sso.linking.create_user") }}</div>
                    </template>
                  </CustomButton>
                  <AppSeparator v-if="environmentOauthServices?.length" />
                  <CustomButton
                    v-for="(service, serviceId) in environmentOauthServices"
                    :key="serviceId"
                    class="env-oauth login-button"
                    flex
                    type="default"
                    :margin-top="15"
                    :border="false"
                    :disabled="loading"
                    @click="oauthEnvClick(service.servicetype)"
                  >
                    <template #text>
                      <img alt="service" :src="service.image" />
                      <span
                        v-text="
                          $t('sso.sign_in_with', { service: service.name })
                        "
                      />
                    </template>
                  </CustomButton>
                </div>
              </Form>
            </div>
          </div>
        </Transition>
      </div>
    </template>
  </FullPageModal>
</template>
<script setup>
import TextField from "@/components/form/TextField.vue";
import PasswordField from "@/components/form/PasswordField.vue";
import CustomButton from "@/components/CustomButton.vue";
import { useKeyCloakStore } from "@/store/modules/keycloak.store";
import FullPageModal from "@/components/FullPageModal.vue";
import { computed, onMounted, ref } from "vue";
import Loading from "@/components/Loading.vue";
import AppSeparator from "@/components/AppSeparator.vue";
import { useEnvironmentStore } from "@/store/modules/environment.store";
import {
  ENVIRONMENT_FORGOT_PASSWORD_PATH,
  OAUTH_ACTION_LINK,
  ORIGIN_TYPE,
} from "@/views/pages/LoginOptions";
import { formEnvironmentOauthUrl } from "@/util/helpers";

const props = defineProps({
  environment: {
    type: Object,
    required: true,
  },
  productId: {
    type: String,
    default: null,
  },
  redirectPathName: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(["close:popup", "success"]);

const keycloakStore = useKeyCloakStore();
const environmentsStore = useEnvironmentStore();

const linkLoading = ref(false);
const registerLoading = ref(false);
const loading = ref(false);
const isLoginEnvironment = ref(false);

const environmentOauthServices = computed(
  () =>
    environmentsStore.environmentOauthServices[props.environment.environment],
);
const environmentName = computed(
  () => props.environment.name || props.environment.environment,
);

const linkUser = async (event) => {
  linkLoading.value = true;
  const isLinked = await keycloakStore.linkUser(
    event.target.username.value,
    event.target.password.value,
    props.environment.environment,
  );

  if (isLinked) {
    emit("success");
  }
  linkLoading.value = false;
};

const register = async () => {
  registerLoading.value = true;
  const isRegistered = await keycloakStore.registerUser(
    props.environment.environment,
  );

  if (isRegistered) {
    emit("success");
  }
  registerLoading.value = false;
};

const close = () => {
  if (isLoginEnvironment.value) {
    isLoginEnvironment.value = false;
    return;
  }

  emit("close:popup");
};

const oauthEnvClick = async (serviceType) => {
  let redirectUrl;
  if (props.redirectPathName) {
    redirectUrl = props.redirectPathName;
  } else {
    const fullPath = window.location.href.replace(
      window.location.origin + window.location.pathname,
      "",
    );
    redirectUrl = fullPath.replace("/#", "");
  }

  await environmentsStore.getOauthData(
    props.environment.environment,
    serviceType,
  );
  if (environmentsStore.oauthId) {
    const environment = keycloakStore.environmentsList.find(
      (environment) =>
        environment.environment === props.environment.environment,
    );

    window.location.href = formEnvironmentOauthUrl(
      environment.lmsUrl,
      environmentsStore.oauthId,
      redirectUrl,
      ORIGIN_TYPE,
      OAUTH_ACTION_LINK,
    );
  }
};

onMounted(() => {
  environmentsStore.getOauthEnvironments(props.environment.environment);
});
</script>
<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0;
}

.flex-container {
  display: flex;
  height: 100%;

  @media only screen and (max-width: 768px) {
    display: block;
  }

  h2 {
    margin-top: 26px;
    font-size: 32px;
    line-height: 26px;

    @media only screen and (max-width: 768px) {
      font-size: 22px;
    }
  }

  h3 {
    margin-top: 54px;
    font-size: 22px;
    line-height: 26px;

    @media only screen and (max-width: 768px) {
      margin-top: 36px;
    }
  }

  h4 {
    text-align: center;
    margin: 26px auto;
    font-size: 15px;
    line-height: 22px;
    max-width: 360px;
    font-weight: normal;
  }

  .logo {
    width: 100%;
    max-width: 106px;
  }

  .colorfully-block {
    flex: 1;
    background-color: #151f6d;
    position: relative;
    height: 100%;

    @media only screen and (max-width: 768px) {
      padding: 35px 20px;
    }

    .header-inner-block {
      position: relative;
    }

    .back-button {
      padding-top: 30px;

      @media only screen and (max-width: 768px) {
        padding: 0;
      }

      .block {
        color: #ffffff;
        display: flex;
        gap: 12px;
        align-items: center;
        font-size: 12px;
      }
    }

    .header-block {
      display: block;
      margin: 0 auto;
      text-align: center;
      width: 100%;

      h2 {
        color: #ffffff;
      }

      h3 {
        color: #ffffff;
      }

      h4 {
        color: #ffffff;
      }

      .title {
        padding: 20px 0;
      }
    }

    .register-block {
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      align-items: center;

      @media only screen and (max-width: 768px) {
        margin-top: 45px;
      }

      .register-button {
        margin: 0 auto;
        display: block;
        text-align: center;
        color: #ffffff;
        font-size: 15px;
        line-height: 26px;
        max-width: 360px;
        padding: 22px 0;
        width: 100%;
        font-weight: bold;
      }
    }
  }

  .login-environment {
    color: #ffffff;
    font-size: 10px;
    text-decoration: unset;
  }

  .form-block {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 20px;

    .header-block {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      @media only screen and (max-width: 768px) {
        margin: 0 auto 10px;
      }

      h2 {
        color: #1b214a;
      }

      h3 {
        color: #1b214a;
        margin-top: 26px;

        @media only screen and (max-width: 768px) {
          font-size: 15px;
          font-weight: normal;
          margin-top: 16px;
          margin-bottom: 36px;
        }
      }
    }

    .separator {
      margin: 35px 0;

      @media only screen and (max-width: 768px) {
        margin: 16px 0;
      }
    }

    .form {
      max-width: 480px;
      width: 100%;

      .forgot-password {
        color: #64748b;
        font-size: 10px;
        text-decoration: unset;
        float: right;
        padding-top: 10px;
      }

      label {
        color: #64748b;
        font-size: 12px;
        display: none;

        @media only screen and (max-width: 768px) {
          display: block;
        }
      }

      .action-buttons {
        margin-top: 100px;

        @media only screen and (max-width: 768px) {
          margin-top: 40px;
        }

        .login-block {
          display: flex;
          gap: 25px;
          max-width: 480px;
          width: 100%;
          margin: 0 auto;

          .cancel {
            color: #64748b;
            font-size: 15px;

            @media only screen and (max-width: 768px) {
              display: none;
            }
          }

          .login {
            border: 2px solid #151f6d;
            color: #151f6d;
            font-size: 15px;
            font-weight: bold;

            @media only screen and (max-width: 768px) {
              background-color: #151f6d;
              color: #ffffff;
            }
          }
        }

        .env-oauth {
          background-color: #f1f5f9;
          border-radius: 6px;
          max-width: 315px;
          width: 100%;
          padding: 12px 0;
          margin: 0 auto;
          text-align: center;
          display: flex;
          justify-content: center;
          color: #64748b;
          font-size: 15px;

          @media only screen and (max-width: 768px) {
            max-width: 480px;
          }

          img {
            width: 24px;
            height: 24px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

form {
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 20px;
}

h2,
p {
  color: $color-black;
}

.register-button {
  width: 100%;
}

button {
  flex: 1;
  text-align: center;
}

:deep(.modal-block .modal-body) {
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

:deep(.modal-block) {
  padding: 0;
}

.v-enter-active {
  transition: opacity 0.2s ease;
}

.v-enter-active {
  animation: bounce-in 0.2s;
}

.v-enter-from {
  opacity: 0;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
