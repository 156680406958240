<template>
  <div
    :style="{ 'flex-basis': width, position: 'relative', height: height }"
    class="course"
  >
    <router-link :to="{ name: ROUTE.Course.About, params: { id: course.id } }">
      <img class="course__image" :src="course.image" alt="Course Image" />
    </router-link>
    <div class="course__content">
      <div class="course__name">
        {{ course.name }}
        <div class="course__rating-container">
          <div class="course__star">
            <div v-for="n in course.rating" :key="n">
              <img src="../../assets/icons/star-blue.svg" alt="star" />
            </div>
            <div v-for="n in 5 - course.rating" :key="n">
              <img src="../../assets/icons/star-gray.svg" alt="star" />
            </div>
          </div>
          <div class="course__rating">{{ course.rating }}/5</div>
        </div>
      </div>
      <div class="course__content-footer">
        <div class="course__date-available">
          {{ course.date }}
        </div>
        <div v-if="course.price_formatted" class="course__price">
          <template v-if="course.special_formatted">
            <span
              class="course__price-special"
              v-text="course.price_formatted"
            />
            {{ course.special_formatted }}
          </template>
          <template v-else>{{ course.price_formatted }}</template>
        </div>
        <div v-else class="course__price-free">
          {{ $t("course.free") }}
        </div>
      </div>
      <div v-if="course.product_count" class="course__events">
        <div class="course__events-button">
          <CourseAddToFavourites
            v-if="!paid"
            :data="course"
            :dark="true"
            :paid="paid"
            class="course__add-to-favorites"
          />
          <custom-button
            type="default"
            :text="$t('course.events', { n: course.product_count })"
            size="md"
            @click="handleCourseAction"
          />
        </div>
      </div>
      <div v-else class="course__events">&nbsp;</div>
    </div>
  </div>
</template>

<script setup>
import CustomButton from "@/components/CustomButton.vue";
import CourseAddToFavourites from "@/components/course/CourseAddToFavourites.vue";
import { useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";

const props = defineProps({
  course: {
    course: Object,
    default: () => ({
      title:
        "Communication with the patient in the provision, principles of communication",
      price: 19.9,
      rating: 4.8,
      free: false,
    }),
  },
  paid: { type: Boolean, required: true },
  width: { type: String, default: "300px" },
  height: { type: String, default: "unset" },
});

const router = useRouter();

const handleCourseAction = () => {
  router.push({
    name: ROUTE.Course.About,
    params: { id: props.course.id },
  });
};
</script>

<style lang="scss" scoped>
.course {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;

  &__name {
    min-height: 40px;
    flex-grow: 1;
    font-family: $font-family;
    color: $color-blue;
    font-size: $font-size-slg;
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  a {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }

  img {
  }

  &__image {
    opacity: 0.8;
    outline: 100px solid rgba(27, 33, 74, 0.3) !important;
    outline-offset: -100px;
    overflow: hidden;
    position: relative;
  }

  &__content {
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
  }

  &__content-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__rating-container {
    color: $color-blue;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
  }

  &__star {
    display: flex;
    gap: 7px;
    flex: 1;
  }

  &__medal {
  }

  &__date-available {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__rating {
    display: flex;
    justify-content: flex-end;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__price {
    width: 100%;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-slg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    margin-top: 10px;
  }

  &__price-special {
    color: $color-red;
    text-decoration: line-through;
    margin-right: 5px;
    font-size: $font-size-md;
    font-weight: 500;
  }

  &__price-free {
    border-radius: 4px;
    background-color: $color-primary;
    color: #ffffff;
    font-family: $font-family;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
    padding: 3px 7px;
  }

  &__events {
    font-family: $font-family;
    display: flex;
    margin-top: 5px;
    align-items: center;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }
  }

  &__events-label {
    margin-right: auto;
    color: $color-blue;
  }

  &__events-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 11px;

    button {
      width: 100%;
      display: block;
      border: 2px solid $color-blue;
      padding-bottom: 7px;
      font-size: $font-size-md;
      font-weight: 500;
      color: $color-blue;
      border-radius: 5px;

      @media screen and (max-width: $breakpoint-lg) {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }
}
</style>
