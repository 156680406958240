<template>
  <div>
    <Navigation />
    <div class="container">
      <div class="payment">
        <h2>{{ $t("common.payment") }}</h2>

        <div class="payment__row">
          <div class="payment__column">
            <div class="payment__wrap">
              <div
                v-for="payment in cartStore.paymentMethods"
                :key="payment.code"
                class="payment__block"
                :class="{
                  'payment__block--active':
                    payment.code === selected_payment_method,
                }"
                @click="selectPayment(payment.code)"
              >
                <div
                  :class="{
                    radius__active: payment.code == selected_payment_method,
                  }"
                  class="radius"
                >
                  <div class="radius__inner"></div>
                </div>
                <div class="payment__title">
                  <div>{{ $t(`payment.${payment.code}`) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="payment__column payment__column--sidebar">
            <h3 v-text="$t('common.buyer')" />
            <div class="payment__sidebar">
              <div class="payment__cart-row payment__form-group">
                <CheckboxField
                  v-model="buyAsCompany"
                  name="buyAsCompany"
                  :label="$t('common.buy_as_company')"
                />
              </div>

              <div v-if="buyAsCompany" class="payment__company-fields">
                <Form ref="companyForm">
                  <TextInputField
                    rules="required"
                    name="company_name"
                    :label="$t('common.company_name')"
                    :placeholder="$t('common.company_name_placeholder')"
                  />
                  <TextInputField
                    rules="required"
                    name="company_code"
                    :label="$t('common.company_code')"
                    :placeholder="$t('common.company_code_placeholder')"
                  />
                  <TextInputField
                    rules="required"
                    name="company_address"
                    :label="$t('common.company_address')"
                    :placeholder="$t('common.company_address_placeholder')"
                  />
                  <TextInputField
                    name="company_vat"
                    :label="$t('common.company_vat')"
                    :placeholder="$t('common.company_vat_placeholder')"
                  />
                </Form>
              </div>
            </div>

            <h3 v-text="$t('common.sum')" />
            <div v-if="!cartStore.totalsIsEmpty" class="payment__sidebar">
              <div
                v-for="(total, index) in cartStore.totals"
                :key="index"
                class="payment__cart-row"
              >
                <div class="payment__cart-row-text">{{ total.title }}:</div>
                <div class="payment__cart-row-value">{{ total.text }}</div>
              </div>

              <p class="payment__terms">
                {{ $t("common.by_completing_the_payment_you_agree_to") }}
                <router-link :to="{ name: 'page', params: { id: termsPage } }">
                  {{ $t("common.terms_and_conditions") }}
                </router-link>
                .
              </p>

              <CustomButton
                :type="'dark'"
                :full-width="true"
                :text="$t('common.payment_checkout')"
                :disabled="cartStore.loading"
                @click="pay"
              />
            </div>
            <div
              v-else
              class="payment__terms"
              v-text="$t('common.cart_empty')"
            />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation";
import CustomButton from "@/components/CustomButton";
import { useCartStore } from "@/store";
import { nextTick, onMounted, ref, watch } from "vue";
import { Form } from "vee-validate";
import TextInputField from "@/components/form/TextInputField.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";

const cartStore = useCartStore();

const selected_payment_method = ref(null);
const companyForm = ref(null);
const buyAsCompany = ref(false);
const termsPage = process.env.VUE_APP_TERMS_PAGE;

const pay = async () => {
  // retry: registered user may have issues on first payment
  if (!(await cartStore.selectPaymentMethod(selected_payment_method.value))) {
    if (await cartStore.getPaymentMethods()) {
      await cartStore.selectPaymentMethod(selected_payment_method.value);
    }
  }

  if (buyAsCompany.value) {
    const validation = await companyForm.value.validate();
    if (!validation.valid) {
      return;
    }

    await cartStore.setCompanyInfo(companyForm.value.getValues());
  } else {
    await cartStore.unsetCompanyInfo();
  }

  await cartStore.pay();
};

const selectPayment = (value) => {
  selected_payment_method.value = value;
};
const setCompanyForm = () => {
  buyAsCompany.value = true;
  nextTick(() => companyForm.value.setValues(cartStore.companyInfo));
};

watch(
  () => cartStore.firstMethod,
  (value) => (selected_payment_method.value = value),
  { immediate: true },
);

onMounted(async () => {
  await cartStore.getItems();
  await cartStore.getPaymentMethods();
  if (
    (await cartStore.getCompanyInfo()) &&
    cartStore.companyInfo.company_code
  ) {
    setCompanyForm();
  }
});
</script>

<style lang="scss">
.payment__company-fields .form-group {
  margin-bottom: 15px;

  label {
    margin-bottom: 0;
  }
}

.separate-row {
  margin-bottom: 15px;
}

.error-state-error {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(8px);
  z-index: 9;

  &__content {
    text-align: center;

    img {
      margin-bottom: 60px;
    }

    h3 {
      margin-bottom: 20px;
      text-align: center;

      color: $color-blue;
      font-family: $font-family;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
    }

    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;
      max-width: 600px;

      margin-bottom: 80px;
    }
  }
}

.error-state-wating {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.6);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(8px);
  z-index: 9;

  &__content {
    text-align: center;

    h3 {
      margin-bottom: 20px;
      text-align: center;

      color: $color-blue;
      font-family: $font-family;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px;
    }

    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 23px;

      margin-bottom: 80px;
    }
  }
}

.montonio-payments-country-dropdown {
  padding: 10px;
  min-width: 200px;
}

.montonio-aspsp-grid-logos {
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 11px;
  justify-content: flex-start;
}

.montonio-aspsp-grid-item {
  flex-basis: 100%;
  border: 1px solid #dcdee9;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  max-width: 246px;
  cursor: pointer;

  &.montonio-hidden {
    display: none;
  }

  &.active {
    mix-blend-mode: darken;
    background: lighten($color-primary, 70);
  }

  img {
    object-fit: contain;
    padding: 10px;
    max-height: 107px;
    max-width: 150px;
  }
}

.bank-list {
  &__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 11px;
    justify-content: center;
  }

  &__block {
    flex-basis: 100%;
    border: 1px solid #dcdee9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    max-width: 246px;

    &--active {
      mix-blend-mode: darken;
      background: lighten($color-primary, 70);
    }

    &:hover {
      box-shadow: 5px 5px 10px 0 rgba(27, 33, 74, 0.07);
      background: lighten($color-primary, 70);

      img {
        mix-blend-mode: darken;
      }
    }
  }
}

.selected-payment-block {
  margin-bottom: 80px;

  &__content {
    font-family: $font-family;
  }

  &__title {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;

    margin-bottom: 30px;
  }

  &__description {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    gap: 10px;
    font-family: $font-family;

    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 28px;
    }
  }

  &__card-list {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__block {
    border: 1px solid #dcdee9;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__column {
    flex-basis: 100%;
  }
}

.radius {
  height: 22px;
  width: 22px;
  border: 1px solid #dcdee9;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__active {
    border: 1px solid $color-primary;

    .radius__inner {
      display: block;
    }
  }

  &__inner {
    display: none;
    border-radius: 11px;
    background-color: $color-primary;
    height: 10px;
    width: 10px;
  }
}

.payment {
  margin-bottom: 21px;

  &__terms {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 35px;
    margin-bottom: 30px;

    a {
      color: $color-primary;
    }
  }

  &__title {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__total-text {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__discount-block {
    margin-bottom: 10px;
  }

  &__total {
    color: $color-blue;
    font-family: $font-family;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 46px;

    margin-bottom: 25px;
  }

  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    gap: 0 47px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 500px;
      @media screen and (max-width: 900px) {
        flex-basis: 100%;
      }
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
  }

  &__block {
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #dcdee9;
    padding: 30px 30px;
    gap: 15px;
    cursor: pointer;

    &--active {
      box-shadow: 5px 5px 10px 0 rgba(27, 33, 74, 0.07);
    }
  }

  &__cart-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;

    &--bold {
      .payment__cart-row-text {
        font-weight: 500;
      }

      .payment__cart-row-value {
        font-weight: 500;
        font-size: 26px;
        line-height: 48px;
      }
    }
  }

  &__cart-row-text {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__cart-row-value {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 21px;
  }
}
</style>
