<template>
  <div>
    <Navigation />
    <CourseHeader
      v-if="purchasedStore.course"
      :course="purchasedStore.course"
      :paid="true"
      :back-route="ROUTE.Dashboard.Courses"
    />
    <div v-if="purchasedStore.course" class="course-page">
      <CourseNavigationTabs
        :tests="purchasedStore.tests"
        :surveys="purchasedStore.surveys"
      />

      <div class="container">
        <router-view />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import CourseHeader from "@/components/course/CourseHeader";
import CourseNavigationTabs from "@/components/course/CourseNavigationTabs";
import { getPurchasedStore } from "@/store/modules/purchased.store";
import { useRoute } from "vue-router";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { ROUTE } from "@/router/routenames";

export default {
  components: {
    Navigation,
    Footer,
    CourseHeader,
    CourseNavigationTabs,
  },
  setup() {
    const route = useRoute();
    const auth2Store = useAuth2Store();
    const purchasedStore = getPurchasedStore();

    return {
      route,
      purchasedStore,
      auth2Store,
    };
  },
  computed: {
    ROUTE() {
      return ROUTE;
    },
    refreshToken() {
      return `${this.$i18n.locale}|${this.auth2Store.token}`;
    },
  },
  watch: {
    refreshToken: {
      immediate: true,
      async handler() {
        return await Promise.all([
          this.purchasedStore.request(this.route.params.id),
          this.purchasedStore.requestSurveys(this.route.params.id),
          this.purchasedStore.requestTests(this.route.params.id),
        ]);
      },
    },
    "route.name": {
      async handler() {
        this.purchasedStore.request(this.route.params.id);
        this.purchasedStore.requestSurveys(this.route.params.id);
        this.purchasedStore.requestTests(this.route.params.id);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.course-page {
  &__row {
    display: flex;
    align-items: flex-start;
    gap: 26px;
    margin-top: 40px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-basis: 100%;

    &--sidebar {
      flex-basis: 100%;
      max-width: 393px;
      @media screen and (max-width: 900px) {
        max-width: initial;
      }
    }
  }
}
</style>
