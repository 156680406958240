<template>
  <div class="tabs tabs--sticky">
    <div class="container">
      <div class="tabs__wrap">
        <router-link
          active-class="tabs__tab--active"
          :to="{
            name: 'course.general',
            params: {
              id: route.params.id,
              environment: route.params.environment,
            },
          }"
          class="tabs__tab"
        >
          {{ $t("common.general_part") }}
        </router-link>
        <router-link
          active-class="tabs__tab--active"
          :to="{
            name: 'course.theory',
            params: {
              id: route.params.id,
              environment: route.params.environment,
            },
          }"
          class="tabs__tab"
        >
          {{ $t("common.theoretical_material") }}
        </router-link>
        <router-link
          v-if="tests?.length"
          active-class="tabs__tab--active"
          :to="{
            name: 'course.tests',
            params: {
              id: route.params.id,
              environment: route.params.environment,
            },
          }"
          class="tabs__tab"
        >
          {{ $t("common.tests") }}
        </router-link>
        <router-link
          v-if="surveys?.length"
          active-class="tabs__tab--active"
          :to="{
            name: 'course.surveys',
            params: {
              id: route.params.id,
              environment: route.params.environment,
            },
          }"
          class="tabs__tab"
        >
          {{ $t("course.surveys") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  props: {
    course: {},
    tests: null,
    surveys: null,
  },
  setup() {
    const route = useRoute();

    return {
      route,
    };
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  border-bottom: 1px solid $color-light-grey;
  position: sticky;
  z-index: 4;
  background-color: #fff;
  top: $header-height;

  @media screen and (max-width: $breakpoint-xl) {
    top: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 20px;
  }

  .container {
    padding: 0;
  }

  &__wrap {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  &__tab {
    display: block;
    padding: 19px 10px;

    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;

    text-decoration: none;
    white-space: nowrap;

    border-bottom: 3px solid transparent;

    &--active {
      color: $color-blue;
      border-bottom: solid 3px $color-blue;
    }

    &:hover {
      color: $color-blue;
      border-bottom: solid 3px $color-blue;
    }
  }
}
</style>
