<template>
  <div>
    <div id="app">
      <moodle-auth />
      <moodle-log-out />
      <video-modal />
      <modal-container />
      <alert-container />
      <div class="page-container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import AlertContainer from "@/components/AlertContainer.vue";
import VideoModal from "@/components/modals/VideoModal.vue";
import { useAuth2Store } from "./store/modules/auth2.store";
import { useKeyCloakStore } from "./store/modules/keycloak.store";
import MoodleAuth from "@/components/MoodleAuth.vue";
import ModalContainer from "@/components/modals/ModalContainer.vue";
import MoodleLogOut from "@/components/MoodleLogOut.vue";
import { isShoppingEnabled } from "@/util/helpers";

export default {
  components: {
    MoodleLogOut,
    ModalContainer,
    VideoModal,
    AlertContainer,
    MoodleAuth,
  },
  setup() {
    const auth2Store = useAuth2Store();
    const keycloakStore = useKeyCloakStore();
    return { auth2Store, keycloakStore };
  },
  created() {
    setInterval(this.auth2Store.refresh, 10000);
    this.keycloakStore.getEnvironments();
  },
  methods: { isShoppingEnabled },
};
</script>

<style lang="scss">
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

.bg-grey {
  background: #f8f8f9;
}

.container {
  max-width: $max-width-container;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
}

.page-container > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.form-group {
  margin-bottom: 30px;

  label {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;

    display: inline-block;

    margin-bottom: 13px;

    input,
    select,
    textarea {
      &::placeholder {
        opacity: 0.5;
        color: $color-blue;
        font-family: $font-family;
        font-size: $font-size-sm;
        letter-spacing: 0;
        line-height: 23px;
      }
    }
  }
}

h2 {
  color: $color-blue;
  font-family: $font-family;
  font-weight: 500;
  font-size: $font-size-lg;
  line-height: 15px;
}

.menu-mobile--body-hidden {
  overflow: hidden;
  height: 1px !important;
}
</style>
