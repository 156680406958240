import { defineStore } from "pinia";
import { computed, ref } from "vue";
import backService from "@/plugins/service";

export const useEnvironmentStore = defineStore("environment", () => {
  const environment = ref(null);
  const environmentOauthServices = ref([]);
  const oauthId = ref(null);
  const setEnvironment = (newEnvironment) =>
    (environment.value = newEnvironment);

  const reset = () => setEnvironment(null);

  const getOauthEnvironments = async (environment) => {
    if (environmentOauthServices.value[environment]) {
      return;
    }
    const { data } = await backService.get("/oauth-available-services", {
      params: { environment },
    });

    environmentOauthServices.value[environment] = data;
  };

  const getOauthData = async (environment, serviceType) => {
    const { data } = await backService.get(
      "/oauth-available-services/" + serviceType,
      {
        params: {
          environment: environment,
        },
      },
    );

    oauthId.value = data.id;
  };

  return {
    environment,
    setEnvironment,
    reset,
    getOauthEnvironments,
    environmentOauthServices: computed(() => environmentOauthServices.value),
    getOauthData,
    oauthId: computed(() => oauthId.value),
  };
});
