import { defineStore } from "pinia";
import i18n from "@/i18n";

const textSuccess = i18n.global.t("common.success");
const textError = i18n.global.t("common.error");

export const useAlertStore = defineStore({
  id: "alert",
  state: () => ({
    alerts: [],
  }),
  actions: {
    removeByIndex(index) {
      this.alerts.splice(index, 1);
    },

    removeByLine() {
      if (this.alerts.length !== 0) {
        setTimeout(() => {
          this.alerts.shift();
        }, 5000 * this.alerts.length);
      }
    },

    warning(subject, message, remove = false) {
      this.alerts.push({
        message,
        subject,
        type: "warning",
      });

      if (remove) {
        this.removeByLine();
      }
    },

    success(subject = textSuccess, message, remove) {
      this.alerts.push({
        message,
        subject,
        type: "success",
      });

      if (remove === undefined) {
        this.removeByLine();
      }
    },

    error(subject = textError, message, remove) {
      this.alerts.push({
        message,
        subject,
        type: "danger",
      });

      if (remove === undefined) {
        this.removeByLine();
      }
    },
    clear() {
      this.alerts = [];
    },
  },
});
