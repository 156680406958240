import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { buildDataFromChapters } from "@/util/helpers";

export const usePageStore = defineStore("pageStore", () => {
  const request = async (id) => {
    const { data } = await backService.get(`/information/${id}`);

    return buildDataFromChapters(data?.chapters || []);
  };

  const book = async (name) => {
    const { data } = await backService.get(`pages/${name}`);

    return data;
  };

  return { request, book };
});
