<template>
  <div class="course-header">
    <ShareCourse v-if="share" @close="toggleShare" />

    <div class="course-header__header">
      <img class="course-header__header-bg" :src="course.image" alt="course" />
      <div
        class="course-header__header-content"
        :class="{
          'course-header-large-padding': course.products?.length > 1 || paid,
        }"
      >
        <div class="container">
          <div class="nav-row">
            <div>
              <router-link :to="{ name: backRoute }" class="breadcrumbs__back">
                <img src="@/assets/arrow_long_left_white.svg" alt="back" />
              </router-link>
            </div>
            <div class="course-header__actions-wrapper">
              <div class="shareMobile" @click="toggleShare">
                <img src="@/assets/share.svg" alt="Share" />
              </div>
            </div>
          </div>

          <CourseRating v-if="!paid" :rating="course.rating" />

          <div class="course-header__header-title">
            <h2 v-if="paid">{{ course.title || "" }}</h2>
            <h2 v-else>{{ course.title }}</h2>
          </div>

          <div
            v-if="!!mobileDescription && !paid"
            class="course-header__header-description"
            v-text="mobileDescription"
          />

          <div
            v-if="!paid & !!courseDuration"
            class="course-header__header-duration"
          >
            <img src="@/assets/clock.svg" alt="Duration" />
            <span v-text="courseDuration" />
          </div>

          <div v-if="paid" class="course-header__progress">
            <PercentOval :percent="percent" />
            <div class="course-header__progress-text">
              {{ $t("common.completed") }} {{ course.completed.done }} /
              {{ course.completed.total }}
            </div>
          </div>
          <div
            v-if="paid"
            class="course-header__grade"
            :class="{ 'cursor-pointer': courseGrade?.showGrades }"
            @click="openGrades"
          >
            <span
              class="course-header__grade__label"
              v-text="$t('grades.grade_text')"
            />
            <span
              class="course-header__grade__value"
              v-text="courseGrade?.grade"
            />
            <img
              v-if="courseGrade?.showGrades"
              alt="link"
              src="../../assets/icons/open-white.svg"
            />
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import PercentOval from "@/components/PercentOval";
import ShareCourse from "@/components/modals/ShareCourse";
import { computed, onMounted, ref, watch } from "vue";
import { getFirstSentence, stripTags } from "@/util/helpers";
import CourseRating from "@/components/course/CourseRating.vue";
import { ROUTE } from "@/router/routenames";
import { useRouter } from "vue-router";
import { useGradesStore } from "@/store/modules/grades.store";

const props = defineProps({
  breadcrumbs: Array,
  course: Object,
  paid: Object,
  backRoute: { type: String, default: ROUTE.Discover },
});

const gradesStore = useGradesStore();

const router = useRouter();

const share = ref(false);

const backRoute = computed(() => props.backRoute || ROUTE.Discover);
const percent = computed(() => {
  let calculation = Math.round(
    (props.course.completed.done / props.course.completed.total) * 100,
  );

  return isNaN(calculation) ? 10 : calculation;
});
const mobileDescription = computed(() => {
  if (!props.course.shortDescription && !props.course.fullDescription) {
    return null;
  }

  return getFirstSentence(
    stripTags(props.course.shortDescription || props.course.fullDescription),
  );
});
const courseId = computed(
  () => props.course.environment + "_" + props.course.shortname,
);
const courseGrade = computed(() => gradesStore.gradesByCourse[courseId.value]);
const courseDuration = computed(() => {
  let productsDurations = [];

  if (!props.course || !("products" in props.course)) {
    return null;
  }

  props.course?.products?.forEach((product) => {
    productsDurations.push(+product.course_duration);
  });

  productsDurations = productsDurations.filter((num) => num > 0);
  productsDurations = [...new Set(productsDurations)];

  if (productsDurations.length === 1) {
    return productsDurations[0] + "h";
  } else if (productsDurations.length > 1) {
    const min = Math.min(...productsDurations);
    const max = Math.max(...productsDurations);

    return min + "h - " + max + "h";
  } else {
    return null;
  }
});

onMounted(() =>
  gradesStore.coursesGrades([
    {
      course: props.course.shortname,
      environment: props.course.environment,
    },
  ]),
);

watch(
  () => props.course.shortname,
  () => {
    gradesStore.coursesGrades([
      {
        course: props.course.shortname,
        environment: props.course.environment,
      },
    ]);
  },
);

const toggleShare = () => (share.value = !share.value);

const openGrades = async () => {
  if (!courseGrade.value.showGrades) {
    return;
  }
  await router.push({
    name: "grades",
    params: {
      course: props.course.id,
      environment: props.course.environment,
    },
  });
};
</script>

<style lang="scss" scoped>
.course-header__header-content.course-header-large-padding {
  padding-bottom: 120px;

  @media only screen and (max-width: $breakpoint-sm) {
    padding-bottom: 156px;
  }
}

.course-header {
  position: relative;
  overflow: hidden;
  display: flex;
  z-index: 1;

  &__progress {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 40px;
  }

  &__progress-text {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  &__grade {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 0;

    &__value {
      font-size: $font-size-lg;
      font-family: $font-family;
      font-weight: 500;
      color: $color-white;
    }

    &__label {
      font-size: $font-size-slg;
      font-family: $font-family;
      font-weight: 500;
      color: $color-white;
    }
  }

  &__header {
    width: 100%;
    position: relative;
  }

  &__header-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__header-content {
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 31, 109, 0.3);

    display: flex;
    align-items: center;
    z-index: 5;
    position: relative;
    padding-bottom: 15px;

    @media screen and (max-width: 900px) {
      align-items: flex-start;
      padding: 15px 0;
    }

    .nav-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .shareMobile {
        cursor: pointer;

        img {
          height: 24px;
          width: 24px;
        }
      }
    }
  }

  &__actions-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;

    &__add-to-favorites {
      margin-right: 15px;
    }
  }

  &__header-title {
    display: flex;
    justify-content: space-between;

    margin-top: 25px;

    flex-wrap: wrap;

    h2 {
      color: $color-white;
      font-family: $font-family;
      font-size: $font-size-xl;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 44px;
    }
  }

  &__header-description {
    font-family: $font-family;
    font-weight: 400;
    font-size: $font-size-md;
    line-height: 22px;
    color: $color-white;
    margin: 15px 0;
  }

  &__header-duration {
    display: flex;
    font-family: $font-family;
    font-weight: 500;
    font-size: $font-size-md;
    line-height: 22px;
    color: $color-white;
    margin: 15px 0;

    img {
      height: 24px;
      width: 24px;
      margin-right: 15px;
    }
  }

  &__header-rating {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__header-star {
    img {
      height: 14px;
      width: 14px;
    }
  }

  &__header-rating-text {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
  }

  &__header-medal {
    img {
      height: 21px;
      width: 15px;
    }
  }

  &__header-price {
    color: $color-white;
    font-family: $font-family;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 46px;

    margin-top: 65px;
    margin-bottom: 25px;

    @media screen and (max-width: 900px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.breadcrumbs__back {
  display: flex;
  align-items: center;

  img {
    height: 24px;
    width: 24px;
  }
}

:deep(.percent-block) .percent-block__text {
  color: $color-white;
}
</style>
