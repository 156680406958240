import axios from "axios";
import { defineStore } from "pinia";
import { restoreLocalStorageJSON } from "@/plugins/misc";
import { useSettingsStore } from "@/store/modules/settings.store";
import { computed, ref } from "vue";

const LOCAL_STORAGE_KEY = "ACCOUNT_DATA";

export const useAccountStore = defineStore("account", () => {
  const settingsStore = useSettingsStore();

  const loading = ref(false);
  const error = ref(null);
  const data = ref(restoreLocalStorageJSON(LOCAL_STORAGE_KEY));
  const update = ref(null);

  const customerId = computed(() => {
    if (!data.value) return null;
    if (!data.value.data) return null;

    return data.value.data.customer_id || null;
  });

  const values = computed(() => {
    return {
      email: "",
      firstname: "",
      lastname: "",
      telephone: "",
      ...((data.value || {}).data || {}),
    };
  });

  const reset = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);

    data.value = null;
  };

  const updateData = async (values) => {
    loading.value = true;

    try {
      const { data } = await axios.put("/account", values);
      update.value = data;

      return true;
    } catch (error) {
      loading.value = false;
      error.value = error;

      return false;
    }
  };
  const notSetup = async () => {
    loading.value = true;
    await settingsStore.request();

    return !(
      !!settingsStore?.values?.date_of_birth &&
      !!settingsStore?.values?.firstname &&
      !!settingsStore?.values?.lastname &&
      !!settingsStore?.values?.email &&
      !!settingsStore?.values?.telephone
    );
  };

  return {
    loading,
    error,
    data,
    update,
    customerId,
    values,
    reset,
    updateData,
    notSetup,
  };
});
