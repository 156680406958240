<template>
  <div class="grid" :style="{ '--columns': columns }">
    <div class="container">
      <h3 v-if="title" v-text="title" />
      <div class="grid__wrap">
        <a
          v-for="(item, index) in items"
          :id="'page-' + (item.id || index)"
          :key="item.id"
          href="#"
          class="grid__block"
          @click.prevent="handleClick(item)"
        >
          <img :src="item.image" alt="Background" />
          <div class="grid__content">
            <div class="grid__content-text">
              <span class="counter" v-text="formatIndex(index + 1)" />
              <h4 v-text="item.title" />
              <img
                class="grid__content-text-arrow"
                src="@/assets/arrow-long-right-white.svg"
                alt="arrow"
              />
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { formatIndex } from "@/util/helpers";

const MAX_COLUMNS_IN_ROW = 6;

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  items: {
    type: Array,
    required: true,
  },
  onClick: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(["itemClick"]);

const columns = computed(() =>
  Math.min(props.items.length, MAX_COLUMNS_IN_ROW),
);

const handleClick = (item) => {
  props.onClick(item);
};
</script>

<style lang="scss">
.grid {
  .container {
    max-width: unset;
    padding: 0;
  }

  @media screen and (max-width: $breakpoint-lg) {
    border-top: none;
  }

  h3 {
    color: $color-blue;
    font-family: $font-family;
    font-size: $header-h3-font-size;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    padding-bottom: 40px;
    padding-top: 49px;
    text-align: center;

    @media screen and (max-width: $breakpoint-lg) {
      padding-top: 0;
    }
  }

  &__wrap {
    display: grid;
    grid-template-columns: repeat(var(--columns, 4), 1fr);
    gap: 0;
    margin-top: 20px;

    @media screen and (max-width: $breakpoint-lg) {
      grid-template-columns: repeat(2, 1fr);
      gap: 9px;
      padding: 0;
    }
  }

  &__block {
    height: 560px;
    width: 100%;
    text-decoration: none;
    position: relative;
    display: block;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;

    @media screen and (max-width: $breakpoint-lg) {
      height: 280px;
    }

    img:not(.grid__content-text-arrow) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: -23px;
      position: absolute;
    }

    --hover-scale: calc(1.7 - (6 - var(--columns)) * 0.12);

    &:first-child:hover,
    &:last-child:hover,
    &:hover:not(:first-child):not(:last-child) {
      z-index: 1;
      width: calc(100% * var(--hover-scale));

      .grid__content-text-arrow {
        height: 24px;
        visibility: visible;
        margin-top: 40px;
      }
    }

    &:first-child:hover {
      margin-left: 0;
    }

    &:last-child:hover {
      margin-left: calc(-1 * (var(--hover-scale) - 1) * 100%);
    }

    &:hover:not(:first-child):not(:last-child) {
      margin-left: calc(-1 * ((var(--hover-scale) - 1) / 2) * 100%);
    }
  }

  &__content-text {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 80px;
    align-items: center;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 80px;
    }
  }

  &__content {
    position: absolute;
    align-content: center;
    bottom: 23px;
    width: 100%;
    background: rgba(21, 31, 109, 0.3)
      radial-gradient(
        circle,
        rgba(21, 31, 109, 0.8) 0%,
        rgba(21, 31, 109, 0.2) 100%
      );
    height: 100%;

    h4 {
      color: $color-white;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
      text-align: center;
    }

    .counter {
      color: $color-white;
      font-family: $font-family;
      font-size: $font-size-xxl;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
    }

    &-text-arrow {
      visibility: hidden;

      @media screen and (max-width: $breakpoint-lg) {
        visibility: visible;
        position: absolute;
        bottom: 40px;
      }
    }
  }
}
</style>
