import { defineStore } from "pinia";
import { computed, ref } from "vue";
import backService from "@/plugins/service";
import { buildDataFromChapters } from "@/util/helpers";

export const useDiscountStore = defineStore("discount", () => {
  const loading = ref(false);
  const discount = ref(null);

  const discountBanner = computed(() => {
    if (discount.value === null) {
      return null;
    }

    const bannerData = buildDataFromChapters(discount.value?.chapters || []);

    return {
      discount: bannerData["discount"] || "",
      color: bannerData["color"] || "",
      image: bannerData["image"],
      description: bannerData["description"] || "",
      link: bannerData["link"] || "",
      link_text: bannerData["link_text"] || "",
      title: bannerData["title"] || "",
    };
  });

  async function request() {
    try {
      loading.value = true;
      const { data } = await backService.get("discounts");

      loading.value = false;
      discount.value = data;

      return true;
    } catch (error) {
      loading.value = false;
      console.error(error);

      return false;
    }
  }

  return {
    discount,
    discountBanner,
    loading,
    request,
  };
});
