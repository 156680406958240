<template>
  <CoursePage :course="course">
    <template #content>
      <div v-if="course.reviews">
        <div id="reviews" class="course-page__section">
          <div class="course-page__section-block">
            <h3 class="course-page__section-header">
              {{ $t("course.reviews") }}
            </h3>
            <span class="course-page__section-block__results">{{
              $t("course.results", { count: course.reviews.length })
            }}</span>
          </div>

          <div class="course-page__section-content">
            <div v-if="course.reviews" class="reviews">
              <div v-if="course.reviews.length" class="reviews__wrap">
                <div
                  v-for="review in course.reviews"
                  :key="review"
                  class="reviews__block"
                >
                  <img
                    v-if="review.image"
                    class="reviews__image"
                    :src="review.image"
                    alt="image"
                  />
                  <div v-else class="initials">
                    {{ getInitials(review.name, review.lastName) }}
                  </div>
                  <div class="reviews__content">
                    <div class="reviews__course__star">
                      <div v-for="n in review.rating" :key="n">
                        <img
                          src="../../../assets/icons/star-blue.svg"
                          alt="star"
                        />
                      </div>
                      <div v-for="n in 5 - review.rating || 0" :key="n">
                        <img
                          src="../../../assets/icons/star-gray.svg"
                          alt="star"
                        />
                      </div>
                    </div>
                    <p class="reviews__text">
                      {{ review.text }}
                    </p>
                    <div class="reviews__name">
                      <div class="reviews__fullname">
                        {{ review.name }}
                      </div>
                      <div class="reviews__subname">
                        {{ review.position }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="reviews__error">
                {{ $t("course.reviews_empty") }}
              </div>
            </div>
          </div>
          <div v-if="!userReviewExists" class="add-review-block">
            <custom-button
              class="add-review"
              type="dark"
              :margin-top="0"
              size="md"
              @click="addReview"
            >
              <template #text class="sss">
                <div class="caption">
                  <img src="@/assets/icons/chat-white.svg" alt="Chat" />
                  <span v-text="$t('reviews.leave_review')" />
                </div>
              </template>
            </custom-button>
          </div>
        </div>
      </div>
    </template>
  </CoursePage>
  <AddReview v-if="showReviewForm" @close:modal="closeReviewForm" />
</template>
<script setup>
import CoursePage from "@/views/pages/CoursePage.vue";
import { computed, onMounted, ref } from "vue";
import { useProductsStore } from "@/store/modules/products.store";
import { useRoute } from "vue-router";
import useIsMobile from "@/util/useIsMobile";
import CustomButton from "@/components/CustomButton.vue";
import AddReview from "@/components/modals/AddReview.vue";
import { useSettingsStore } from "@/store/modules/settings.store";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { getInitials } from "@/util/helpers";

const route = useRoute();
const { checkWidth } = useIsMobile();

const productsStore = useProductsStore();
const settingsStore = useSettingsStore();
const auth2Store = useAuth2Store();

const showReviewForm = ref(false);

const course = computed(() => {
  return productsStore.current || {};
});
const userReviewExists = computed(
  () =>
    !!course.value?.reviews.find(
      (review) =>
        review.userId === settingsStore.values.contact_info.user_id ||
        (review.external_id &&
          review.external_id === auth2Store.tokenPayload.sub),
    ),
);

onMounted(() => {
  productsStore.requestCurrent(route.params.id);
  checkWidth();
});

const addReview = () => (showReviewForm.value = true);
const closeReviewForm = () => (showReviewForm.value = false);
</script>
<style lang="scss" scoped>
.course-page {
  &__section {
    &-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: $breakpoint-lg) {
        display: grid;
        align-items: unset;
        justify-content: unset;
      }

      &__results {
        color: #64748b;
        font-size: 12px;
        font-family: $font-family;

        @media only screen and (max-width: $breakpoint-lg) {
          grid-column-start: 1;
          grid-row-start: 1;
          justify-self: right;
          display: inline-block;
          line-height: 35px;
        }
      }
    }

    &-header {
      @media only screen and (max-width: $breakpoint-lg) {
        grid-column-start: 1;
        grid-row-start: 1;
        justify-self: center;
        display: inline-block;
      }
    }
  }
}

.reviews {
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 22px;
  }

  &__block {
    display: flex;
    align-items: flex-start;
    flex-basis: 100%;
    gap: 20px;
    border: 1px solid #f1f5f9;
    @media only screen and (max-width: $breakpoint-lg) {
      border: unset;
      border-top: 1px solid $color-light-grey;
      border-bottom: 1px solid $color-light-grey;
    }
  }

  &__image {
    border-radius: 40px;
    width: 86px;
    height: 86px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;

    p {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
      letter-spacing: 0;
      line-height: 28px;
    }
  }

  &__course {
    &__star {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &__fullname {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  &__subname {
    opacity: 0.5;
    color: #1b214a;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__name {
  }

  &__error {
    color: #1b214a;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }
}

.add-review-block {
  margin-top: 40px;
  text-align: center;

  .add-review {
    margin-bottom: 80px;
    color: $color-white;
    border-radius: 8px;
    padding: 15px;
    max-width: 335px;
    width: 100%;
    margin-top: 40px;

    .caption {
      display: flex;
      gap: 18px;
      align-items: center;
      justify-content: center;
    }
  }
}

.initials {
  position: relative;
  display: flex;
  width: 86px;
  height: 86px;
  align-items: center;
  justify-content: center;
  background-color: #f1f5f9;
  border-radius: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #171f69;
}
</style>
