<template>
  <HighlightGrid
    :title="$t('home.courses-by-category.header')"
    :items="categoriesItems"
    :on-click="enterCategory"
  />
</template>

<script setup>
import axios from "axios";
import { useRouter } from "vue-router";
import { computed, onBeforeMount, ref, watch } from "vue";
import i18n from "@/i18n";
import HighlightGrid from "@/components/grid/HighlightGrid.vue";

const router = useRouter();

const categories = ref([]);
const categoriesItems = computed(() =>
  categories.value.map((category) => ({
    id: category.category_id,
    title: category.name,
    image: category.original_image,
  })),
);

const enterCategory = (category) => {
  router.push({
    name: "discover",
    query: {
      category_id: category.id,
    },
  });
};
const getCategories = async () => {
  try {
    const response = await axios.get("/categories/parent/59");
    categories.value = response.data.data;
  } catch (error) {
    console.error("Failed to fetch categories:", error);
  }
};

onBeforeMount(async () => await getCategories());

watch(
  () => i18n.global.locale,
  async () => await getCategories(),
);
</script>

<style lang="scss"></style>
