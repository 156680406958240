<template>
  <div class="subject" :class="{ 'subject-first-page': pagesFirst }">
    <div class="subject__header" v-text="subject.title" />
    <CourseSubjectItemPages v-if="pagesFirst" :pages="subject.pages" />
    <div class="subject__wrap">
      <template v-for="material in subject.parts" :key="material.id">
        <accordion v-if="material.is_group" :padding="34">
          <accordion-item :disabled="!material.subparts.length">
            <template #accordion-trigger="{ isOpen }">
              <div class="subject__accordion-trigger">
                <div class="subject__accordion-title">
                  <div
                    v-if="material.completion === MANUAL_COMPLETION"
                    class="subject__completion-status"
                    :class="{
                      'subject__completion-status--done': material.completed,
                    }"
                    @click.stop="setCompletion(material)"
                  >
                    <img :src="CorrectWhiteIcon" alt="check" />
                  </div>
                  <div
                    v-else-if="material.completion === AUTO_COMPLETION"
                    class="subject__completion-status"
                    :class="{
                      'subject__completion-status--done': material.completed,
                    }"
                  >
                    <img :src="CorrectWhiteIcon" alt="check" />
                  </div>
                  <h3 v-dompurify-html="material.title" />
                </div>
                <img
                  v-if="material.subparts.length"
                  :src="isOpen ? MinusBlueIcon : PlusBlueIcon"
                  alt="svg"
                />
              </div>
            </template>
            <template #accordion-content>
              <div class="group-items">
                <CourseMaterialItem
                  v-for="part in material.subparts"
                  :key="part.id"
                  :material="part"
                  @set:completion="setCompletion"
                />
              </div>
            </template>
          </accordion-item>
        </accordion>
        <div v-else class="group-items">
          <CourseMaterialItem
            :material="material"
            @set:completion="setCompletion"
          />
        </div>
      </template>
    </div>
    <CourseSubjectItemPages v-if="!pagesFirst" :pages="subject.pages" />
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import AccordionItem from "@/components/accordion/AccordionItem.vue";
import Accordion from "@/components/accordion/Accordion.vue";
import CourseMaterialItem from "@/components/course/CourseMaterialItem.vue";
import PlusBlueIcon from "@/assets/icons/plus-blue.svg";
import MinusBlueIcon from "@/assets/icons/minus-blue.svg";
import CorrectWhiteIcon from "@/assets/correct_white.svg";
import { useRoute } from "vue-router";
import {
  AUTO_COMPLETION,
  MANUAL_COMPLETION,
  useCompletionsStore,
} from "@/store/modules/completions.store";
import CourseSubjectItemPages from "@/components/course/CourseSubjectItemPages.vue";

defineProps({
  subject: {},
  pagesFirst: { default: false, type: Boolean },
});

const completionsStore = useCompletionsStore();

const { setCompleted } = completionsStore;

const route = useRoute();

const setCompletion = async (material) => {
  await setCompleted(
    material.module,
    material.id,
    !material.completed,
    route.params.id,
  );
};
</script>

<style lang="scss" scoped>
.subject {
  margin-bottom: $header-height;
  font-family: $font-family;

  &__completion-status {
    height: 24px;
    width: 32px;
    background-color: rgba($color-black, 0.4);
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 24px;
    min-width: 32px;

    img {
      transform: unset;
    }

    &--done {
      background: $color-green;
    }
  }

  .group-items {
    padding: 0 10px;
    border-bottom: 1px solid $color-light-grey;

    &:last-child {
      box-shadow: none;
    }
  }

  .accordion {
    margin-bottom: 0;

    .group-items {
      padding: 0 12px 0 0;
    }
  }

  &__header {
    color: $color-blue;
    font-size: $font-size-md;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid $color-light-grey;
  }

  &__show-more {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 70px;
    gap: 15px;

    span {
      height: 34px;
      width: 34px;
      border-radius: 17px;
      background-color: $color-primary;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__wrap {
    margin-top: 20px;
  }

  .accordion__item {
    border-bottom: 1px solid $color-light-grey;
    border-bottom: unset;
  }

  &__accordion-title {
    display: flex;
    gap: 10px;
  }

  &__accordion-trigger {
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    h3 {
      color: $color-blue !important;
      font-weight: 500;
      font-family: $font-family;
      font-size: $font-size-slg;
    }
  }

  video {
    max-width: 100%;
    height: auto;
  }
}

.subject-first-page {
  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 0;
  }
}
</style>
