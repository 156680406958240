<template>
  <Form :initial-values="initialValues">
    <div class="settings__wrap">
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">
            {{ $t("common.workplace") }}
          </div>
        </div>
        <div class="settings__item-setting">
          <SelectInputField
            v-model="workplace"
            name="organisation_id"
            :placeholder="$t('common.organisation_placeholder')"
            :options="workplaceOptions"
            :fetch-options="getOrganisations"
            @input="(value) => setWorkplace(value)"
          />
        </div>
      </div>
      <div class="settings__item">
        <div class="settings__item-text">
          <div class="settings__item-text-name">
            {{ $t("common.responsibilities") }}
          </div>
        </div>
        <div class="settings__item-setting">
          <SelectInputField
            v-model="position"
            name="position_id"
            :placeholder="$t('common.position_placeholder')"
            :options="positionOptions"
            :fetch-options="getPositions"
            @input="(value) => setPosition(value)"
          />
        </div>
      </div>
    </div>
  </Form>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import { Form } from "vee-validate";
import SelectInputField from "@/components/form/SelectInputField.vue";
import { useOrganisationStore } from "@/store/modules/organisation.store";
import { storeToRefs } from "pinia";

const emit = defineEmits(["submit"]);
const props = defineProps({ initialValues: Object, loading: Boolean });

const organisationStore = useOrganisationStore();
const { getOrganisations, getPositions } = organisationStore;
const { organisations, positions } = storeToRefs(organisationStore);

const workplace = ref(props.initialValues.organisation_id);
const position = ref(props.initialValues.position_id);

const workplaceOptions = computed(() => [
  ...organisations.value,
  ...(props.initialValues.organisation
    ? [props.initialValues.organisation]
    : []),
]);
const positionOptions = computed(() => [
  ...positions.value,
  ...(props.initialValues.position ? [props.initialValues.position] : []),
]);

const setWorkplace = (value) => {
  if (value === props.initialValues.organisation?.value) {
    return;
  }
  emit("submit", { organisation: value || 0 });
};
const setPosition = (value) => {
  if (value === props.initialValues.position?.value) {
    return;
  }
  emit("submit", { position: value || 0 });
};
</script>

<style lang="scss" scoped>
.spacer {
  padding: 10px;
}

#file_upload {
  display: none;
}

.settings {
  &__upload-photo {
    display: block;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
    cursor: pointer;
  }

  &__item-setting {
    @media only screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }

    :deep(.form-group) {
      margin: 0;
    }

    :deep(.form-control) {
      margin: 0;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  &__profile-name {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__profile-value {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &__profile-fullname {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }

  &__profile-image {
    text-align: center;

    img {
      width: 90px;
      height: 90px;
      object-fit: cover;
      border-radius: 64px;
    }
  }

  &__profile-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

    @media screen and (max-width: 900px) {
      justify-content: center;
    }
  }

  &__profile-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      gap: 10px;
    }

    a {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-sm;
      letter-spacing: 0;
      line-height: 19px;

      text-decoration: none;
    }
  }

  &__profile-settings-block {
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }

  &__profile-block {
    display: flex;
    align-items: center;
    gap: 40px;
    padding-top: 60px;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  h4 {
    margin-bottom: 20px;

    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-lg;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
  }

  &__wrap {
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $color-light-grey;
    padding: 8px 20px;
    min-height: $header-height;
    gap: 16px;

    &:last-child {
      border-bottom: 1px solid $color-light-grey;
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
    }
  }

  &__item-text-name {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    font-weight: 400;
    line-height: 22px;
  }

  &__item-text-description {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-xs;
    line-height: 22px;
  }
}
</style>
