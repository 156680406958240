<template>
  <Teleport to="body">
    <LinkToEnvironment
      v-if="showPopup"
      :environment="scheduleEnvironment"
      allow-registration
      :redirect-path-name="redirectPathName"
      @close:popup="close"
      @success="addToCart"
    />
  </Teleport>
</template>
<script setup>
import { useCartStore } from "@/store";
import { useLoginRoute } from "@/util/helpers";
import { defineEmits, ref, watch } from "vue";
import { useSettingsStore } from "@/store/modules/settings.store";
import { useKeyCloakStore } from "@/store/modules/keycloak.store";
import LinkToEnvironment from "@/components/modals/LinkToEnvironment.vue";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { useRoute, useRouter } from "vue-router";

const emit = defineEmits(["added", "error", "closed"]);
const props = defineProps({
  course: Object,
  selectedProduct: Object,
});

const cartStore = useCartStore();
const auth2Store = useAuth2Store();
const settingsStore = useSettingsStore();
const keycloakStore = useKeyCloakStore();

const router = useRouter();
const route = useRoute();

const showPopup = ref(false);
const scheduleEnvironment = ref(null);
const product = ref(null);
const productId = ref(null);
const redirectPathName = ref(null);

const checkIfEnvironmentIsLinked = () => {
  if (!product.value?.environment) {
    emit("error", "Unknown environment");
    return;
  }
  if (!auth2Store.isLoggedIn) {
    const loginRoute = useLoginRoute();
    const redirectUrl = new URL(
      loginRoute.value.query.redirect,
      window.location.origin,
    );
    redirectUrl.searchParams.set("product_id", product.value.product_id);
    loginRoute.value.query.redirect = redirectUrl.pathname + redirectUrl.search;
    router.push(loginRoute.value);

    return;
  }
  if (!settingsStore.values.environments[product.value.environment]) {
    scheduleEnvironment.value = keycloakStore.environmentsList.find(
      (environment) => environment.environment === product.value.environment,
    );
    const loginRoute = useLoginRoute();
    const redirectUrl = new URL(
      loginRoute.value.query.redirect,
      window.location.origin,
    );
    redirectPathName.value = redirectUrl.pathname + redirectUrl.search;
    productId.value = product.value.product_id;
    showPopup.value = true;
    return;
  }

  addToCart();
};

const addToCart = () => {
  cartStore.add(
    product.value.product_id,
    1,
    settingsStore.values.environments[product.value.environment],
  );
  showPopup.value = false;
  scheduleEnvironment.value = null;
  product.value = null;

  emit("added");
};

const close = () => {
  showPopup.value = false;
  emit("closed");
};

watch(
  [() => props.course, () => settingsStore.values.environments],
  async () => {
    if (
      props.course &&
      (route.query.product_id || productId.value) &&
      settingsStore.values.environments
    ) {
      const currentProductId = route.query.product_id || productId.value;
      const currentProduct = props.course.products.find(
        (product) => product.product_id === parseInt(currentProductId),
      );
      if (currentProduct) {
        product.value = currentProduct;
        checkIfEnvironmentIsLinked();
        await router.replace({ query: { ...route.query, product_id: null } });
      }
    }
  },
  { immediate: true },
);

watch(
  () => props.selectedProduct?.product_id,
  (productId) => {
    if (productId) {
      product.value = props.selectedProduct;
      checkIfEnvironmentIsLinked();
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped></style>
