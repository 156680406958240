<template>
  <component
    :is="href ? 'a' : to ? 'router-link' : 'button'"
    class="btn"
    :to="to"
    :href="href"
    :type="submit ? 'submit' : 'button'"
    :style="{ marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px` }"
    :class="[
      { 'btn--fullwidth': fullWidth },
      `btn--${type}`,
      { 'btn--disabled': disabled },
      `btn--${size}`,
      { 'btn--flex': flex },
      { 'border-unset': !border },
    ]"
  >
    <span v-if="text" v-text="text" />
    <slot name="text"></slot>
  </component>
</template>

<script>
export default {
  props: {
    marginBottom: {
      type: Number,
      default: 0,
    },
    marginTop: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    text: {},
    flex: {
      type: Boolean,
      default: false,
    },
    size: {
      default: "xl",
      type: String,
    },
    type: {
      default: "default",
    },
    to: {},
    href: String,
    border: {
      type: Boolean,
      default: true,
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      if (this.to) {
        location.href = this.to;
      }
    },
  },
};
</script>

<style lang="scss">
.btn.border-unset {
  border: unset;
}

.btn {
  border-radius: 8px;
  min-width: 150px;
  background: transparent;
  font-family: $font-family;
  text-decoration: none;
  text-align: center;

  cursor: pointer;

  &--flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &--xxl {
    padding: 13px 64px;
  }

  &--xl {
    padding: 16px 30px;
  }

  &--md {
    padding: 10px 30px;
  }

  &--fullwidth {
    width: 100%;
  }

  &--disabled {
    opacity: 0.2;
  }

  &--white {
    border: 2px solid white;
    background: white;
    color: $color-blue;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  &--dark {
    border: 2px solid $color-primary;
    background-color: $color-primary;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  &--dark-login {
    border-radius: 8px;
    border: 2px solid $color-primary;
    background-color: $color-primary;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  &--copy {
    border: 2px solid $color-primary;
    background-color: $color-primary;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 12px;
    padding: 15px 24px;
    min-width: auto;
  }

  &--default {
    border: 2px solid $color-primary;
    color: $color-blue;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
  }

  &--blue-transparent {
    background-color: transparent;
    border: 2px solid $color-primary;
    color: $color-primary;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }

  &--grey-transparent {
    border: 2px solid #dcdee9;
    background: transparent;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;
  }

  &--round-white {
    border: 2px solid #dcdee9;
    border-radius: 24px;
    background-color: #ffffff;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
  }
}
</style>
