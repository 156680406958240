<template>
  <div class="form-group">
    <label v-if="label" :for="id">
      {{ label }}
      <slot name="label" />
    </label>
    <input
      :id="id"
      ref="input"
      v-model="innerValue"
      class="form-control"
      :name="name"
      :min="min"
      :max="max"
      :step="step"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="{ 'form-control--is-invalid': !!errors.length }"
      @blur="$emit('blur')"
      @input="(value) => $emit('input', value)"
    />
    <ErrorMessage
      v-if="errors.length && name"
      v-slot="{ message }"
      :name="name"
    >
      <p class="error">{{ message }}</p>
    </ErrorMessage>
  </div>
</template>

<script setup>
import { uniqid } from "@/plugins/misc";
import { ErrorMessage } from "vee-validate";
import { computed, defineEmits, defineExpose, defineProps, ref } from "vue";

const id = uniqid("text-input-");
const input = ref(null);
const emit = defineEmits(["update:modelValue", "blur", "input"]);
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: [String, Number],
  label: String,
  placeholder: String,
  name: String,
  min: Number,
  max: Number,
  step: Number,
  type: {
    type: String,
    default: "text",
  },
  errors: {
    type: Array,
    default: () => [],
  },
});

// eslint-disable-next-line no-unused-vars
const focus = () => input.value.focus();
const innerValue = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

defineExpose({ focus });
</script>

<style lang="scss" scoped>
.error {
  color: red;
  font-family: $font-family;
  font-size: $font-size-sm;
  margin-top: 5px;
}

input {
  display: block;
  font-size: $font-size-md;
  font-weight: 400;
  line-height: 14px;
  border: 1px solid $color-light-grey;
  background-color: $color-white;
  border-radius: 8px;
  padding: 17.5px 15px;
  min-width: 360px;
  width: 100%;

  &:placeholder-shown {
    background-color: $color-light-grey;
  }

  @media screen and (max-width: $breakpoint-sm) {
    min-width: unset;
  }
}
</style>
