<template>
  <div>
    <QRCode v-if="modal" @close="toggleQr()" />
    <div class="navbar">
      <div class="navbar__wrap container">
        <div class="navbar__controls">
          <router-link class="navbar__brand" :to="{ name: 'home' }">
            <img src="../assets/menu/hl-logo.svg" />
          </router-link>
          <router-link class="navbar__back" :to="{ name: 'home' }">
            {{ $t("common.back_to_website") }}
          </router-link>
        </div>

        <div class="navbar__navbar-collapse">
          <ul class="navbar__nav">
            <li v-if="auth2Store.isLoggedIn" class="navbar__item">
              <a
                href="#"
                class="navbar__link navbar__link--icon"
                @click.prevent="modal.value = !modal.value"
              >
                <img src="../assets/icons/qrcode-grey.svg" />
                {{ $t("common.connect_other_device") }}
              </a>
            </li>
            <li class="navbar__item navbar__item--dropdown">
              <LanguageToggle />
              <div class="navbar__dropdown-menu">
                <LanguageDropdown />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import QRCode from "@/components/modals/QRCode.vue";
import { ref } from "vue";
import LanguageDropdown from "@/components/navbar/LanguageDropdown.vue";
import LanguageToggle from "@/components/navbar/LanguageToggle.vue";
import { useAuth2Store } from "@/store/modules/auth2.store";

const auth2Store = useAuth2Store();

const modal = ref(false);
</script>

<style lang="scss">
.navbar {
  background: white;

  &--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  &__back {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-decoration: none;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__navbar-collapse {
    ul {
      padding-left: 0;
      list-style-type: none;
      display: flex;
    }
  }

  &__nav {
  }

  &__item {
    &--dropdown {
      position: relative;
      z-index: 9;

      &:hover {
        .navbar__dropdown-menu {
          display: flex;
        }
      }
    }

    &--button {
      border: 1px solid #dcdee9;
      border-radius: 4px;
      width: 87px;
      display: flex;
      justify-content: center;

      a {
        color: $color-blue;
        font-size: $font-size-sm;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 19px;
        text-align: right;
      }
    }
  }

  &__dropdown-menu {
    display: none;
    background: #ffffff;
    border-radius: 4px;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: 0;
    width: 260px;
    box-shadow: 0 3px 15px 0 rgba(27, 33, 74, 0.15);
  }

  &__dropdown-item {
    padding: 20px;

    color: $color-blue;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;
    text-decoration: none;

    display: flex;
    align-items: center;

    img {
      margin-right: 20px;
      height: 26px;
      width: 26px;
    }

    &:hover {
      background: rgba(9, 31, 44, 0.04);
    }
  }

  &__link {
    padding: 15px 15px;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 21px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;

    @media screen and (max-width: $breakpoint-lg) {
      padding: 5px;
    }

    &--icon {
      img {
        margin-right: 10px;
      }
    }
  }

  &__cart {
    position: relative;

    span {
      position: absolute;
      top: -10px;
      right: 0;

      color: #ffffff;
      font-family: $font-family;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      text-align: center;

      height: 18px;
      width: 18px;
      background-color: $color-primary;
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 20px;
    }
  }
}
</style>
