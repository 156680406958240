<template>
  <Modal :name="'add_review'" :width="720" :toggle="true" @close="closeModal">
    <template #header>
      <h3>{{ $t("reviews.add_review") }}</h3>
    </template>
    <template #body>
      <div class="add-review">
        <Avatar
          :image="settingsStore.values.image"
          :account="settingsStore.values"
          color="blue"
          size="large"
          class="avatar"
        />
        <div class="rating">
          <button v-for="n in 5" :key="n" @click="selectRating(n)">
            <img :src="[n > rating ? StarGrayIcon : StarBlueIcon]" alt="star" />
          </button>
        </div>
        <TextareaField
          rules="required"
          :placeholder="$t('reviews.add_review')"
          :rows="6"
          @input="setReview"
        />
        <div class="actions">
          <CustomButton
            type="transparent"
            :full-width="true"
            class="cancel-button"
            @click="closeModal"
          >
            <template #text>
              {{ $t("common.cancel") }}
            </template>
          </CustomButton>
          <CustomButton
            type="dark"
            :full-width="true"
            submit
            @click="addReview"
          >
            <template #text>
              {{ $t("common.save") }}
            </template>
          </CustomButton>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import Modal from "@/components/Modal.vue";
import Avatar from "@/components/Avatar.vue";
import { useSettingsStore } from "@/store/modules/settings.store";
import TextareaField from "@/components/form/TextareaField.vue";
import { defineEmits, ref } from "vue";
import StarGrayIcon from "../../assets/icons/star-gray.svg";
import StarBlueIcon from "../../assets/icons/star-blue.svg";
import CustomButton from "@/components/CustomButton.vue";
import { useReviewStore } from "@/store/modules/review.store";
import { useRoute } from "vue-router";

const settingsStore = useSettingsStore();
const reviewStore = useReviewStore();
const route = useRoute();

const review = ref(null);
const rating = ref(null);

const emit = defineEmits(["close:modal"]);

const closeModal = () => emit("close:modal");
const selectRating = (value) => (rating.value = value);
const setReview = (event) => (review.value = event.target?.value);

const addReview = () => {
  reviewStore.storeReview(route.params.id, rating.value, review.value);
  emit("close:modal");
};
</script>

<style lang="scss" scoped>
.add-review {
  .avatar {
    width: 86px;
    height: 86px;
    border-radius: 50%;
    margin: 0 auto;
    display: block;
  }

  .rating {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    gap: 12px;

    button {
      background: transparent;
      border: unset;
      padding: 0;
      cursor: pointer;
    }
  }

  :deep(textarea) {
    border: unset;
    background: $color-light-grey;
    border-radius: 8px;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 26px;

    @media only screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
    }

    .cancel-button {
      border: 2px solid $color-menu-item;
    }
  }
}
</style>
