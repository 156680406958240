<template>
  <div class="separator">
    <hr />
    <div class="text" v-text="$t('common.or')" />
  </div>
</template>

<style lang="scss" scoped>
.separator {
  position: relative;
  padding: 20px 0;

  hr {
    margin: 0;
    width: 100%;
  }

  .text {
    position: absolute;
    color: #64748b;
    font-size: $font-size-xxs;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    background-color: #ffffff;
    padding: 0 20px;
  }
}
</style>
