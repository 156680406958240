import { createApp, watch } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import "./plugins/index";
import i18n from "./i18n";
import mitt from "mitt";
import VCalendar from "v-calendar";
import "./util/object.extensions";
import VueDOMPurifyHTML from "vue-dompurify-html";
import "@fontsource/ubuntu";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";
import "./assets/styles/style.scss";
import { useStores } from "@/store/use.store";

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.emitter = emitter;
app.use(VCalendar, {});

app.use(i18n);
app.use(router);
app.use(createPinia());
app.use(VueDOMPurifyHTML, {
  default: {
    FORBID_ATTR: ["style"],
    ADD_ATTR: ["target"],
  },
});

(async () => {
  const { auth2Store, settingsStore } = useStores();
  let lang = "lt";
  if (auth2Store.token) {
    await settingsStore.request();
    lang = settingsStore.data?.language || "lt";
  }

  i18n.global.locale = lang;

  router.isReady().then(() => {
    app.mount("#app");
  });

  watch(
    () => i18n.global.locale,
    () => {
      localStorage.setItem("current_language", i18n.global.locale);
    },
  );
})();
