<template>
  <div class="page-header">
    <SliderVideo />
    <slot name="image" :image="image">
      <img v-if="image" :src="image" :alt="title" />
    </slot>
    <div class="page-header__content">
      <div class="container" @click.capture="handleClick">
        <slot name="content">
          <h2 v-dompurify-html="title" />
          <p v-dompurify-html="description" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getCurrentInstance, watch } from "vue";
import SliderVideo from "./modals/SliderVideo.vue";
import { useHeaderStore } from "@/store/modules/header.store";

const props = defineProps({
  image: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: "",
  },
  description: {
    type: String,
    default: "",
  },
});

const headerStore = useHeaderStore();
const { checkLightOrDark } = headerStore;

const vm = getCurrentInstance();
const handleClick = (e) => {
  /** @type {HTMLElement} */
  const target = e.target;
  /** @type {import('mitt').Emitter} */
  const emitter = vm.appContext.config.globalProperties.emitter;

  const openVideo = target.closest(".open-video");
  if (openVideo) {
    e.preventDefault();

    emitter.emit("svideo", {
      video: openVideo.href,
    });
  }
};

watch(
  () => props.image,
  () =>
    checkLightOrDark({ image: props.image, color: "rgba(27, 33, 74, 0.6)" }),
  { immediate: true },
);
</script>

<style lang="scss">
.page-header {
  position: relative;
  min-height: 280px;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 280px;
  }

  &__content {
    top: 0;
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    width: 100%;
    min-height: 280px;
    background: rgba(27, 33, 74, 0.6);

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  h2 {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-xl;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 36px;
    margin-top: 44px;

    @media screen and (max-width: $breakpoint-lg) {
      margin-top: 0;
    }
  }

  p {
    color: $color-white;
    font-family: $font-family;
    font-size: $font-size-md;
    line-height: 15px;
  }

  a {
    display: flex;
    width: fit-content;
    align-items: center;
    font-family: $font-family;
    color: $color-white;
    border: 2px solid white;
    border-radius: 8px;
    padding: 10px 61px;
    text-decoration: none;

    &.open-video::before {
      content: "";
      width: 26px;
      height: 26px;
      display: inline-block;
      background: url("@/assets/icons/play-white.svg");
      margin-right: 10px;
    }
  }
}
</style>
