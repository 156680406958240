<template>
  <div>
    <router-link
      :to="{
        name: 'dashboard.certificates.show',
        params: { id: certificate.id, environment: certificate.environment },
      }"
      class="certificate"
    >
      <div class="certificate__content">
        <div v-if="certificateImage" class="certificate__image">
          <img :src="certificateImage" alt="certificate" />
        </div>
        <div class="certificate__name">
          <div class="certificate__date">
            {{ certificate.valid?.from || "-" }}
          </div>
          <div class="certificate__title">{{ certificate.title }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script setup>
import moment from "moment";
import { computed } from "vue";
import ExtlmsCertificate from "@/assets/certificates/extlms.png";
import PMPCertificate from "@/assets/certificates/pmp.png";

const CERTIFICATE_TYPE_EXTLMS = "extlms";
const CERTIFICATE_TYPE_PMP = "pmp";

const props = defineProps({
  certificate: { type: Object, default: {} },
});

const certificateImage = computed(() => {
  switch (props.certificate.type) {
    case CERTIFICATE_TYPE_EXTLMS: {
      return ExtlmsCertificate;
    }
    case CERTIFICATE_TYPE_PMP: {
      return PMPCertificate;
    }
    default: {
      return null;
    }
  }
});

const percent = computed(() => {
  const startOfDate = moment(props.certificate.valid.from),
    endDate = moment(props.certificate.valid.to),
    todayDate = moment();

  const daysDifference = moment(endDate).diff(startOfDate, "days");
  const difference = todayDate.diff(startOfDate, "days");

  const result = Math.round((difference / daysDifference) * 100);

  return 100 - result + "%";
});
</script>
<style lang="scss" scoped>
.certificate {
  text-decoration: none;

  &__content {
    width: 100%;
  }

  &__image {
    padding-bottom: 26px;

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      min-width: 150px;
      max-width: 270px;
      max-height: 202px;
    }
  }

  &__progress {
    margin-top: 5px;
    position: relative;
    height: 3px;
    // width: 200px;
    background-color: $color-blue;
  }

  &__progress-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    width: 300px;
    opacity: 0.1;
    border-radius: 3px;
    background-color: $color-blue;
  }

  &__validity-date {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }

  &__validity-text {
    color: $color-blue;
    font-family: $font-family;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 21px;

    display: flex;
    align-items: center;
    gap: 10px;

    &--not-valid {
      width: 300px;
      color: $color-red;
    }
  }

  &__validity {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__start-date,
  &__end-date {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 18px;
  }

  &__name {
    width: fit-content;
  }

  &__date {
    color: $color-menu-item;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 26px;
  }

  &__title {
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-md;
    letter-spacing: 0;
    line-height: 22px;
  }
}
</style>
