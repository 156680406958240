export const PROVIDER_MOODLE = "moodle";
export const PROVIDER_KEYCLOAK = "keycloak";

export const OAUTH_ACTION_LOGIN = "login";
export const OAUTH_ACTION_LINK = "link";

export const ORIGIN_TYPE = "eshop";

export const ENVIRONMENT_FORGOT_PASSWORD_PATH =
  "/local/extlms/forgot_password.php";
