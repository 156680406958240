import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { ref } from "vue";
import { useSettingsStore } from "@/store/modules/settings.store";
import { useAlertStore } from "@/store";
import i18n from "@/i18n";
import { showValidationErrors } from "@/util/helpers";

export const useKeyCloakStore = defineStore("keycloakStore", () => {
  const settingsStore = useSettingsStore();
  const alertStore = useAlertStore();

  const environmentsList = ref([]);
  const getEnvironments = async () => {
    try {
      const { data: response } = await backService.get(`/sso-environments`);

      environmentsList.value = response;

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const linkOauthUser = async (accessToken, environment) => {
    try {
      await backService.post(`/keycloak/link-oauth-user`, {
        access_token: accessToken,
        environment: environment,
      });

      await settingsStore.request(true);

      return true;
    } catch (error) {
      console.error(error);
      alertStore.error(
        i18n.global.t("common.error"),
        error.response.data.error.error,
      );

      return false;
    }
  };

  const linkUser = async (username, password, environment) => {
    try {
      await backService.post(`/keycloak/link-user`, {
        user_name: username,
        password: password,
        environment: environment,
      });

      await settingsStore.request(true);

      return true;
    } catch (error) {
      console.error(error);
      if (error.response.data.error) {
        alertStore.error(
          i18n.global.t("common.error"),
          error.response.data.error.error,
        );
      } else {
        showValidationErrors(error.response.status, error.response.data);
      }

      return false;
    }
  };

  const unlinkUser = async (environment) => {
    try {
      await backService.post(`/keycloak/unlink-user`, { environment });

      await settingsStore.request(true);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    }
  };

  const registerUser = async (environment) => {
    try {
      await backService.post(`/keycloak/register-environment-user`, {
        environment,
      });

      settingsStore.request(true);

      return true;
    } catch (error) {
      console.error(error);
      alertStore.error(
        i18n.global.t("common.error"),
        error.response.data.error,
      );

      return false;
    }
  };

  return {
    getEnvironments,
    linkUser,
    unlinkUser,
    environmentsList,
    registerUser,
    linkOauthUser,
  };
});
