<template>
  <div class="course-item__block" @click="openCourse">
    <div class="course-item__left-text">
      <div class="course-item__left-text__img">
        <img :src="course.image" alt="Course" />
      </div>
      <span
        class="course-item__left-text__course-title"
        v-text="course.title"
      />
    </div>
    <div class="course-item__right-text">
      <Progress
        :done="course.completed.done"
        :total="course.completed.total"
        :percent="percent"
        dark-layout
      />
      <div class="course-item__date">{{ date }}</div>
      <button
        class="course-item__right-text__grade"
        :class="{ 'cursor-pointer': courseGrades?.showGrades }"
        @click.stop="openGrades"
      >
        <span v-text="courseGrades?.grade || '-'" />
        <img
          v-if="courseGrades?.showGrades"
          alt="Link"
          src="../../assets/icons/open-grey.svg"
        />
      </button>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Progress from "@/components/Progress.vue";
import { useRouter } from "vue-router";
import { ROUTE } from "@/router/routenames";
import { useSettingsStore } from "@/store/modules/settings.store";
import { useGradesStore } from "@/store/modules/grades.store";

export default {
  components: {
    Progress,
  },
  props: {
    course: {},
  },
  setup() {
    const router = useRouter();
    const settingsStore = useSettingsStore();
    const gradesStore = useGradesStore();

    return {
      router,
      settingsStore,
      gradesStore,
    };
  },
  computed: {
    date() {
      return moment(this.course.date).locale("lt").format("ll");
    },
    percent() {
      return Math.round(
        (this.course.completed.done / this.course.completed.total) * 100,
      );
    },
    courseId() {
      return this.course.environment + "_" + this.course.shortname;
    },
    courseGrades() {
      return this.gradesStore.gradesByCourse[this.courseId];
    },
  },
  methods: {
    async openCourse() {
      await this.router.push({
        name: ROUTE.Course.General,
        params: { id: this.course.id, environment: this.course.environment },
      });
    },
    async openGrades() {
      if (!this.courseGrades.showGrades) {
        return;
      }
      await this.router.push({
        name: "grades",
        params: {
          course: this.course.id,
          environment: this.course.environment,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.course-item {
  &__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-light-grey;
    cursor: pointer;

    @media only screen and (max-width: $breakpoint-sm) {
      display: flex;
      box-shadow: unset;
      flex-direction: column;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  &__block:hover {
    background-color: $color-light-grey;
  }

  &__left-text {
    display: flex;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width: $breakpoint-sm) {
      display: block;

      &__img {
        height: 118px;
      }
    }

    img {
      width: 88px;
      height: 66px;
      object-fit: cover;

      @media only screen and (max-width: $breakpoint-sm) {
        width: 100%;
        height: 118px;
      }
    }

    span {
      @media only screen and (max-width: $breakpoint-sm) {
        display: block;
      }
    }

    &__course-title {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-md;
      line-height: 22px;
      padding-right: 15px;
      word-break: break-word;
    }
  }

  &__right-text {
    display: grid;
    grid-template-columns: 70px auto 1fr;
    align-items: center;
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    line-height: 18px;
    gap: 16px;
    padding: 22px 20px 22px 0;
    width: 340px;

    @media only screen and (max-width: $breakpoint-lg) {
      gap: 4px;
      width: 280px;
    }

    @media only screen and (max-width: $breakpoint-sm) {
      grid-template-columns: 1fr;
      padding: 16px 20px 8px 0;
      gap: 12px;
      width: unset;
    }

    &__grade {
      display: flex;
      align-items: end;
      gap: 10px;
      text-decoration: unset;
      color: $color-primary;
      font-size: $font-size-md;
      background: transparent;
      border: unset;
      min-width: 70px;
      padding: 0;
      justify-content: flex-end;

      @media only screen and (max-width: $breakpoint-sm) {
        justify-content: flex-start;
      }
    }
  }

  &__date {
    color: $color-blue;
    font-size: $font-size-xs;
    text-align: right;

    @media only screen and (max-width: $breakpoint-sm) {
      text-align: center;
      color: $color-menu-item;
      height: 36px;
    }
  }
}
</style>
