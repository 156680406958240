<template>
  <div class="settings__profile-image">
    <Loading v-if="loading" :size="106" color="black" />
    <Avatar
      v-else
      :account="account"
      :image="value"
      color="blue"
      size="large"
      @click="triggerInput"
    />
    <span
      v-if="showClickableText"
      class="clickable-text"
      @click="triggerInput"
      v-text="clickableText"
    />
    <slot name="content" />
    <input
      :id="id"
      ref="input"
      type="file"
      accept="image/*"
      @change="handleChange"
    />
  </div>
</template>

<script setup>
import { uniqid } from "@/plugins/misc";
import { defineEmits, defineProps, ref } from "vue";
import Loading from "../Loading.vue";
import Avatar from "@/components/Avatar.vue";

const id = uniqid("file-upload-");
const emit = defineEmits(["input"]);

defineProps({
  account: { type: Object, required: true },
  value: { type: String, required: true },
  loading: Boolean,
  showClickableText: { type: Boolean, default: false },
  clickableText: { type: String, default: undefined },
});

const input = ref(null);

const handleChange = (e) => {
  emit("input", e.target.files[0]);
};
const triggerInput = () => {
  input.value?.click();
};
</script>

<style lang="scss" scoped>
.spacer {
  padding: 10px;
}

input[type="file"] {
  display: none;
}

.settings {
  &__upload-photo {
    display: block;
    color: $color-primary;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 19px;
    cursor: pointer;
  }

  &__profile-image {
    text-align: center;
    cursor: pointer;

    img {
      width: 106px;
      height: 106px;
      object-fit: cover;
      border-radius: 64px;
    }
  }
}

.clickable-text {
  color: $color-menu-item;
  font-size: $font-size-xs;
  display: block;
  margin-top: 10px;
}
</style>
