import axios from "axios";
import i18n from "@/i18n";
import { convertEnToGb, handleRefresh } from "./axios";
import { useAuth2Store } from "@/store/modules/auth2.store";
import { useAlertStore } from "@/store";
import { useEnvironmentStore } from "@/store/modules/environment.store";

const backService = axios.create({
  baseURL: process.env.VUE_APP_SERVICE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export const axiosResponseInterceptorOnError = (instance) => async (error) => {
  const auth2Store = useAuth2Store();
  const alertStore = useAlertStore();

  if (error && error.response) {
    if (error.response.status === 429) {
      alertStore.warning(
        "Per greitai vykdote užklausas.",
        "Prašome palaukti vieną minutę kol vėl galėsite toliau naudotis svetaine.",
      );
    }

    if ([401, 403].includes(error.response.status)) {
      if (auth2Store.isLoggedIn) {
        return await handleRefresh(instance, error);
      }
    }
  }

  throw error;
};

backService.interceptors.request.use(async (request) => {
  const auth2Store = useAuth2Store();
  const environmentStore = useEnvironmentStore();

  request.headers["Accept-Language"] = i18n.global.locale;
  request.headers["X-Oc-Merchant-Language"] = convertEnToGb(i18n.global.locale);

  if (environmentStore.environment) {
    request.headers["X-Environment"] = environmentStore.environment;
  }
  if (auth2Store.token) {
    request.headers.Authorization = `Bearer ${auth2Store.token}`;
  }

  return request;
});

backService.interceptors.response.use(
  (response) => response,
  axiosResponseInterceptorOnError(backService),
);

export default backService;
