import backService from "@/plugins/service";
import { defineStore } from "pinia";
import { ref } from "vue";
import { getPurchasedStore } from "@/store/modules/purchased.store";

export const useGradesStore = defineStore("gradesStore", () => {
  const purchasedStore = getPurchasedStore();

  const loading = ref(true);
  const data = ref([]);
  const showGrades = ref(false);
  const gradesByCourse = ref([]);

  const coursesGrades = async (courses) => {
    if (!courses.length) {
      return [];
    }

    const { data } = await backService.get("/course/user-courses-grades", {
      params: { courses: courses },
    });
    gradesByCourse.value = data;
  };

  const request = async () => {
    const course = purchasedStore.course.shortname;
    if (!course) {
      return;
    }
    try {
      const { data: response } = await backService.get(
        `/course/${course}/user-grades`,
      );

      showGrades.value = response["showGrades"];
      data.value = showGrades.value ? response : [];
      loading.value = false;

      return true;
    } catch (error) {
      console.error(error);
      loading.value = false;

      return false;
    }
  };

  return { data, showGrades, request, coursesGrades, gradesByCourse, loading };
});
