import { watch } from "vue";
import { isShoppingEnabled, sleep } from "./helpers";
import { ROUTE } from "@/router/routenames";

export function useAuthTokenHandler({
  auth2Store,
  cartStore,
  accountStore,
  socialStore,
  router,
  route,
}) {
  const isUserBuyingCourse = (redirectUrl) => {
    const url = new URL(redirectUrl, window.location.origin);
    const productId = url.searchParams.get("product_id");

    if (!productId || isNaN(Number(productId))) {
      return false;
    }

    const resolved = router.resolve(redirectUrl);

    return resolved.name === ROUTE.Course.About;
  };

  const setupAuthWatcher = () => {
    watch(
      () => auth2Store.token,
      (value) => {
        if (!value) {
          return;
        }

        // get cart and timeout fixes cart items doubling after login
        cartStore.getItems();
        sleep(500).then(async () => {
          await socialStore.passwordChangePreference();
          const redirect = route.query.redirect;
          if (
            (await accountStore.notSetup()) &&
            !isUserBuyingCourse(redirect)
          ) {
            if (redirect !== "/") {
              localStorage.setItem("redirect", redirect);
            }
            return await router.push({
              name: ROUTE.Dashboard.Settings,
            });
          }

          if (redirect) {
            await router.replace(redirect);
            return;
          }

          socialStore.forcePasswordChange
            ? await router.push({
                name: ROUTE.Dashboard.Settings,
                query: { openChangePassword: "true" },
              })
            : await router.replace({
                name: isShoppingEnabled()
                  ? ROUTE.Home
                  : ROUTE.Dashboard.Courses,
              });
        });
      },
      { immediate: true },
    );
  };

  return {
    setupAuthWatcher,
  };
}
