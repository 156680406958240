<template>
  <div>
    <Navigation />

    <div class="information">
      <div class="container" :class="{ 'container--with-image': hasImage }">
        <Loading v-if="loading" />
        <div
          v-if="hasImage"
          class="information__image"
          :style="{ backgroundImage: `url(${encodeURI(data.image)})` }"
        >
          <img
            v-if="number"
            class="information__image-back"
            :src="ArrowLongLeftWhite"
            alt="Back"
            @click="goBack"
          />
        </div>
        <div class="information__text-container">
          <h1 v-if="number" v-text="number" />
          <h2 v-dompurify-html="data.title" />
          <div
            v-dompurify-html="data.description"
            class="information__text-container-content"
          />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import Loading from "@/components/Loading.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, reactive, ref, watch } from "vue";
import i18n from "@/i18n";
import ArrowLongLeftWhite from "@/assets/arrow_long_left_white.svg";
import backService from "@/plugins/service";
import { buildDataFromChapters } from "@/util/helpers";

const route = useRoute();
const router = useRouter();

const data = reactive({});
const loading = ref(false);

const pageId = computed(() => route.meta.id || route.params.id);
const bottom = computed(() => route.query.bottom === "true");
const number = computed(() =>
  route.query.number !== null ? route.query.number : null,
);
const hasImage = computed(() => data?.image && !data.image.endsWith("/image/"));

const getInformationPage = async (id) => {
  loading.value = true;
  try {
    const response = await backService.get(
      `/information/${id}` + (bottom.value ? "?bottom=true" : ""),
    );
    Object.assign(data, buildDataFromChapters(response.data?.chapters || []));
  } catch (error) {
    console.error("Failed to fetch information:", error);
  } finally {
    loading.value = false;
  }
};
const goBack = () => {
  if (!number.value) {
    return;
  }

  router.push({ name: "about", query: { scrollTo: "page-" + pageId.value } });
};

watch(pageId, async (newValue) => await getInformationPage(newValue));
watch(
  () => i18n.global.locale,
  async () => await getInformationPage(pageId.value),
);

onMounted(async () => await getInformationPage(pageId.value));
</script>

<style lang="scss">
.information {
  .container {
    display: flex;
    justify-content: center;
    padding: 0;
    max-width: none;
    min-height: calc(100vh - 319px);

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      height: auto;
    }

    &--with-image {
      .information__text-container {
        padding: 36px 74px;

        @media screen and (max-width: $breakpoint-lg) {
          padding: 36px;
        }
      }
    }

    &:not(&--with-image) {
      .information__text-container {
        max-width: $max-width-container;
      }
    }
  }

  &__image {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &-back {
      align-self: start;
      margin-top: 20px;
      margin-right: auto;
      cursor: pointer;

      padding-left: 120px;

      @media screen and (max-width: $breakpoint-xl) {
        padding-left: 20px;
      }
    }

    @media screen and (max-width: $breakpoint-lg) {
      flex: none;
      width: 100%;
      height: 600px;
      min-height: 400px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex: none;
      width: 100%;
      height: 300px;
      min-height: 200px;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  &__text-container {
    flex: 3;
    padding: 20px;

    @media screen and (max-width: $breakpoint-lg) {
      flex: none;
      width: 100%;
    }

    h1 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-xl;
      font-weight: 500;
      line-height: 26px;
      text-decoration: none;
      margin: 26px 0;
    }

    h2 {
      color: $color-primary;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      text-align: right;
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;
      margin: 26px 0;

      @media screen and (max-width: $breakpoint-sm) {
        text-align: center;
      }
    }

    &-content {
      font-size: $font-size-md;
      color: $color-primary;
      font-family: $font-family;
      margin-bottom: 30px;

      ul {
        li {
          line-height: 1.38;
        }
      }

      p {
        @media screen and (max-width: $breakpoint-sm) {
          line-height: 30px;
        }
      }

      img {
        width: 50%;

        @media screen and (max-width: $breakpoint-lg) {
          width: 75%;
        }

        @media screen and (max-width: $breakpoint-sm) {
          width: 100%;
        }
      }
    }
  }
}
</style>
