<template>
  <div v-if="content" v-dompurify-html="content" class="page-block"></div>
</template>

<script>
import backService from "@/plugins/service";
import { buildDataFromChapters } from "@/util/helpers";

export default {
  props: {
    id: [String, Number],
  },
  data() {
    return { content: null };
  },
  watch: {
    "$i18n.locale": {
      immediate: true,
      async handler() {
        const response = await backService.get(`/information/${this.id}`);
        this.content = buildDataFromChapters(
          response.data?.chapters || [],
        )?.description;
      },
    },
  },
};
</script>

<style lang="scss">
.page-block * {
  font-family: $font-family !important;
}
</style>
