<template>
  <div>
    <Navigation />
    <div class="container">
      <div v-if="chapters[0]" class="contacts">
        <h2 v-dompurify-html="chapters[0].title" />
        <div v-dompurify-html="chapters[0].content" class="contacts__info" />

        <div class="contacts__wrap">
          <div class="contacts__block">
            <template v-if="chapters[1]">
              <h3 v-dompurify-html="chapters[1].title" />
              <h4 v-dompurify-html="chapters[1].content" />
            </template>

            <div class="contacts__props">
              <div
                v-if="chapters[2]"
                v-dompurify-html="chapters[2].content"
                class="contacts__props-content"
              />
            </div>
          </div>
          <div class="contacts__block">
            <h3 v-if="chapters[4]" v-dompurify-html="chapters[4].title" />

            <Form class="contacts__form" @submit="contact">
              <div class="contacts__name">
                <TextInputField
                  rules="required"
                  name="name"
                  :placeholder="$t('common.your_name')"
                />
              </div>
              <div class="contacts__email">
                <TextInputField
                  rules="required|email"
                  name="email"
                  type="email"
                  :placeholder="$t('common.your_email')"
                />
              </div>
              <div class="contacts__subject">
                <SelectInputField
                  rules="required"
                  name="subject"
                  :options="subjects"
                  :placeholder="$t('common.select_subject')"
                />
              </div>
              <div class="contacts__message">
                <TextareaInputField
                  rules="required"
                  name="message"
                  :placeholder="$t('common.message')"
                />
              </div>
              <div class="contacts__submit">
                <CustomButton :type="'dark'" submit>
                  <template #text>
                    {{ $t("common.send_request") }}
                  </template>
                </CustomButton>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script setup>
import Footer from "@/components/Footer.vue";
import Navigation from "@/components/Navigation.vue";
import CustomButton from "@/components/CustomButton.vue";
import { Form } from "vee-validate";
import { useAlertStore } from "@/store";
import backService from "@/plugins/service";
import TextInputField from "@/components/form/TextInputField.vue";
import SelectInputField from "@/components/form/SelectInputField.vue";
import { ref, watch } from "vue";
import TextareaInputField from "@/components/form/TextareaInputField.vue";
import { usePageStore } from "@/store/modules/page.store";
import i18n from "@/i18n";

const pagesStore = usePageStore();
const alertStore = useAlertStore();
const subjects = ref([]);
const chapters = ref([]);
const loading = ref(true);

const contact = async (values, actions) => {
  await backService
    .post("/contact/by-email", {
      ...values,
    })
    .then(() => {
      actions.resetForm();
      alertStore.success("Ačiū, susisieksime su jumis artimiausiu metu");

      loading.value = false;
    })
    .catch((error) => {
      console.error(error);
      loading.value = false;
    });
};

watch(
  () => i18n.global.locale,
  async () => {
    const { chapters: book } = await pagesStore.book("contacts");
    chapters.value = book;
    const { data: list } = await backService.get("/contact/subjects");
    subjects.value = list.map((x) => ({ name: x, value: x }));
  },
  { immediate: true },
);
</script>
<style lang="scss" scoped>
.contacts {
  &__toggle {
    display: flex;
    align-items: center;
    gap: 20px;
    font-family: $font-family;
    font-size: $font-size-sm;
    margin-top: 30px;
    margin-bottom: 10px;
    font-weight: bold;

    &--icon {
      background-color: transparentize($color: $color-primary, $amount: 0.9);
      border-radius: 30px;
      height: 26px;
      width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 10px !important;
        height: 10px !important;
      }
    }
  }

  margin-bottom: 80px;

  &__props {
    margin-top: 40px;
  }

  &__props-trigger {
    display: flex;
    align-items: center;

    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;

    margin-bottom: 15px;
    gap: 13px;
  }

  &__props-content {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 24px;
  }

  h2 {
    color: $color-blue;
    font-family: $font-family;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 53px;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  p {
    color: $color-blue;
    font-family: $font-family;
    font-size: $font-size-sm;
    letter-spacing: 0;
    line-height: 23px;

    &:first-child {
      margin-bottom: 30px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__column {
    flex-grow: 1;
    flex-basis: 0;
  }

  &__info {
    font-family: $font-family;
    margin-bottom: 80px;

    :deep(a) {
      color: $color-primary;
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  }

  &__block {
    width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    margin-top: 30px;

    h3 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 40px;
    }

    h4 {
      color: $color-blue;
      font-family: $font-family;
      font-size: $font-size-slg;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 29px;
    }
  }
}

.contacts {
  &__form {
    display: grid;
    gap: 0 21px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "name email"
      "subject subject"
      "message message"
      "submit submit";
    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto auto;
      grid-template-areas:
        "name"
        "email"
        "subject"
        "message"
        "submit";
    }
  }

  &__name {
    grid-area: name;
  }

  &__email {
    grid-area: email;
  }

  &__subject {
    grid-area: subject;
  }

  &__message {
    grid-area: message;
  }

  &__submit {
    grid-area: submit;
  }
}
</style>
